import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import PartyService from "./Locality/Service/partyService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import {  useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Party() {
    const tableHeaders = [ 'Party','Edit', 'Delete'];
    const partyHeaders = [
        {label: 'None',      value: 'None'},
        {label: 'Congress',      value: 'Congress'},
        {label: 'BRS', value: 'BRS'   },
        {label: 'BJP', value: 'BJP'   },
        {label: 'TDP', value: 'TDP'   },
        {label: 'Neutral', value: 'Neutral'},
    ];
    const classes = useStyles();
    const [partyList, setPartyList] = useState([]);
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [tabelIdList, setTabelIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [party, setParty] = useState({
        party:'',
    });
    const validationSchema = Yup.object().shape({
        party:Yup.string().required('Party is required'),
    });
    const handleChangePage=(event, newpage) =>{
        setpg(newpage);
    }
    const handleChangeRowsPerPage=(event)=> {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getPartyList();
        return () => {
            setPartyList([]);
        }
    }, []);
    const getPartyList = () => {
        PartyService.getAllParty().then((res) => {
            setPartyList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
    };
    const editParty = (party) => {
        setParty(party);
        handleOpen();
    }
    const deleteParty = (partydelete) => {
        if (partydelete) {
            PartyService.deleteParty(partydelete).then((res) => {
                getPartyList();
            }).catch((err) => {
            });
        }
    };
 
    const formik = useFormik({
        initialValues: party,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (party._id) {
                PartyService.upadeParty(values).then((res) => {
                    handleClose();
                    getPartyList();
                    resetForm()
                    alert("Party Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                PartyService.creteParty(values).then((res) => {
                    getPartyList();
                    resetForm();
                    handleClose();
                    alert(" Party Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        
                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
            <PageTitle title="Party" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#30875b' }}> Add Party
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {partyList.slice(pg * rpg, pg * rpg + rpg).map((party) => (
                                    <TableRow key={party._id}>
                                         <TableCell className="pl-3 fw-normal" >{party.party }</TableCell>

                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editParty(party)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteParty(party)} />
                                        </TableCell>
        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5,  100,1000]}
                            count={partyList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Party</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style= {{ width: 308 }}>
                    <TextField
                                           
                                           style={{ width: 258 }}
                                           id="party"
                                           name="party"
                                           label="Party"
                                           type="text"
                                         
                                           onChange={formik.handleChange}
                                           value={formik.values.party}
                                           error={formik.touched.party && Boolean(formik.errors.party)}
                                           helperText={formik.touched.party && formik.errors.party}
                                       />
                    {/* <FormControl className={classes.formControl}
                      fullWidth="true" >
                      <InputLabel id="demo-simple-select-label">Select Party</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="party"
                        name="party"
                        label="Party"
                        onChange={formik.handleChange}
                        value={formik.values.party}
                        error={formik.touched.caste && Boolean(formik.errors.party)}
                        helperText={formik.touched.party && formik.errors.party}
                      >
                       
                        {partyHeaders?.map(partyHeaders => {
              return (
                <MenuItem key={partyHeaders.value} value={partyHeaders.value}>
                  {partyHeaders.label ?? partyHeaders.value}
                </MenuItem>
              );
          })}
                       
                      </Select>
                    </FormControl> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


