import requests from './httpService';
const ProfileRegistrationService = {
  getAllProfileRegistration() {
    return requests.get('/profileregistration/list');
  },
  getConstituencyByDistrictId(body){
    return requests.post('/profileregistration/getConstituencyByDistrictId',body); 
  },
  creteProfileRegistration(body){
    return requests.post('/profileregistration/add',body); 
  },
  // loginProfileRegistration(body){
  // router.post('/profileregistration/login', body);
  // }
};

export default ProfileRegistrationService;
