import requests from './httpService';
const ContactService = {
  getAllContact() {
    return requests.get(`/contact/list`);
  },
  addContactByConstituenceyId(body){
    return requests.post(`/contact/addContactByConstituenceyId`,body);
  },
  getConstituencyByDistrictId(body){
    return requests.post('/contact/getConstituencyByDistrictId',body); 
  },
  getVillageByConstituencyId(body){
    return requests.post('/contact/getVillageByConstituencyId',body); 
  },
  getBoothByVillageId(body){
    return requests.post('/contact/getBoothByVillageId',body); 
  },
  getCountByVillageId(body){
    return requests.post('/contact/getCountByVillageId',body); 
  },
  creteContact(body){
    return requests.post('/contact/add',body); 
  },
  deleteContact(body){
    return requests.delete(`/contact/${body._id}`); 
  },
  
  findContactList(body){
    return requests.post(`/contact/find`,body); 
  },
  upadeContact(body) {
    return requests.put(`/contact/${body._id}`,body); 
  },
};
export default ContactService;
