import requests from './httpService';
const CasteService = {
 
  getAllCaste() {
    return requests.get(`/caste/list`);
  },

  creteCaste(body){
    return requests.post('/caste/add',body); 
  },

  deleteCaste(body){
    return requests.delete(`/caste/${body._id}`); 
    
  },
  findCasteList(body){
    return requests.post(`/caste/find`,body); 
  },

  upadeCaste(body) {
    return requests.put(`/caste/${body._id}`,body); 
  },

};

export default CasteService;
