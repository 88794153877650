import React, { useState } from "react";
import {
  LinearProgress,
  OutlinedInput,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem,
  TableRow, Table,
  TableHead,
  TableBody,
  TableCell
} from "@material-ui/core";
import PartyService from "./Locality/Service/partyService";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import ProfileRegistration from "./Locality/Service/profileRegistration"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Grid, Card, Box, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import BigStat from "./components/BigStat/BigStat";
import { withStyles } from '@material-ui/core/styles';
export default function ProfileSRegistration(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  var [error, setError] = useState(null);
  const [constituencyList, setConstituencyList] = useState([]);
  const [partyList, setPartyList] = useState([]);
  const [profileRegistrationList, setProfileRegistrationList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [profileRegistration, setProfileRegistration] = useState({
    districtId: '',
    constituencyId: '',
    name: '',
    email: '',
    mobileNumber: '',
    partyId: '',
    password: '',
  });

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object().shape({
    districtId: Yup.string().required('district  is required'),
    constituencyId: Yup.string().required('constituency is required'),
    name: Yup.string().required('name is required'),
    email: Yup.string().required('email is required'),
    mobileNumber: Yup.string().required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits'),
    partyId: Yup.string().required('party is required'),
    password: Yup.string().required('password is required'),
  });
 
  const getProfileRegistrationList = () => {
    ProfileRegistration.getAllProfileRegistration().then((res) => {
      setProfileRegistrationList(res);
    }).catch((err) => {
      setError(err.message);
    });
  }
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    getDistrictList();
     getPartyList();
    return () => {
        setDistrictList([]);
        setConstituencyList([]);

    }
}, []);
const getDistrictList = () => {
  DistrictService.getAllDistrict().then((res) => {
      setDistrictList(res);
  }).catch((err) => {
      // setError(err.message);
  });
}
const getConstituencyList = (destId) => {
const disListI = { districtId: destId };
  ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {
      setConstituencyList(res);
  }).catch((err) => {
      setError(err.message);
  });
}
const getPartyList = () => {
  PartyService.getAllParty().then((res) => {
      setPartyList(res);
  }).catch((err) => {
      setError(err.message);
  });
}
  const formik = useFormik({
    initialValues: profileRegistration,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      ProfileRegistration.creteProfileRegistration(values).then((res) => {

        alert(" Registration Successfully.");
         props.history.push('/montessori/login');
      })
        .catch((err) => {
          alert(err.response.data.message)
        })
    },
  });


  return (

    <>
        <Grid item xs={12}>
          <Card sx={{ maxWidth: 345 }}>
            <Box   >
              <div style={{ marginLeft: "7%" }}>
                <form onSubmit={formik.handleSubmit} >
                  <Grid container spacing={2} columns={12} >
                    <Grid item xs={12}>
                      <PageTitle InputProps={{ style: { color: '#10b680' } }} title="Profile Registration" ></PageTitle>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        InputProps={{ style: { width: 370 } }}
                        margin="dense"
                        id="name"
                        name="name"
                        label="Full Name"
                        type="Full Name"
                        variant="standard"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6} style={{ width: 370 }}>
                    <div style={{ width: 370 }}>
                    <FormControl variant="standard" fullWidth="true">
                            <InputLabel id="demo-simple-select-standard-label">District</InputLabel>
                            <Select  
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                autoFocus
                                margin="dense"
                                name="districtId"
                                label="District"
                                type="text"
                                variant="standard"
                                value={formik.values.districtId}
                                // getConstituencyList();
                                onChange={e => {
                                    formik.handleChange(e);
                                    getConstituencyList(e.target.value)
                                }}
                                error={formik.touched.districtId && Boolean(formik.errors.districtId)}
                                helperText={formik.touched.districtId && formik.errors.districtId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {districtList.map(({ _id, district }) => (
                                    <MenuItem key={_id} value={_id}>{district}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <div style={{ width: 370 }}>
                    <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Constituency </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 370 } }}
                                
                                margin="dense"
                                name="constituencyId"
                                label="Constituency"
                                type="text"
                                variant="standard"
                                value={formik.values.constituencyId}
                                onChange={formik.handleChange}
                                error={formik.touched.constituencyId && Boolean(formik.errors.constituencyId)}
                                helperText={formik.touched.constituencyId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {constituencyList.map(({ _id, constituency }) => (
                                    <MenuItem key={_id} value={_id}>{constituency}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </div>
                    </Grid>
                  
                    <Grid item xs={12} sm={12} md={6}>
                    <div style={{ width: 370 }}>
                    <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Party</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 370 } }}
                                autoFocus
                                margin="dense"
                                name="partyId"
                                label="Party"
                                type="text"
                                variant="standard"
                                value={formik.values.partyId}
                                // getConstituencyList();
                                onChange={formik.handleChange}
                                error={formik.touched.partyId && Boolean(formik.errors.partyId)}
                                helperText={formik.touched.partyId && formik.errors.partyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {partyList.map(({ _id, party }) => (
                                    <MenuItem key={_id} value={_id}>{party}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        InputProps={{ style: { width: 370 } }}

                        margin="dense"
                        id="mobileNumber"
                        name="mobileNumber"
                        label="Mobile Number"
                        type="Mobile Number"
                        variant="standard"
                        value={formik.values.mobileNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                        helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        InputProps={{ style: { width: 370 } }}
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email ID / Username"
                        type="Email ID"
                        variant="standard"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="password"
                        InputProps={{ style: { width: 370 } }}
                        margin="normal"
                        label=" Set Password  "
                        // placeholder="Password"
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right', margin: '29px' }}>
                    <Button style={{ backgroundColor: 'rgb(48 135 91)', color: 'white' }} type="submit" variant="contained" >Submit</Button>
                    </Grid>

                </form>
              </div>
            </Box>

          </Card>
       
      </Grid>
    </>
  );
}


