import requests from './httpService';
const VoterService = {
  getAllVoter() {
    return requests.get(`/voter/list`);
  },
  getConstituencyByDistrictId(body){
    return requests.post('/voter/getConstituencyByDistrictId',body); 
  },
  getVotersByBoothId(body){
    return requests.post('/voter/getVotersByBoothId',body); 
  },
  getVillageByConstituencyId(body){
    return requests.post('/voter/getVillageByConstituencyId',body); 
  },
//   router.post('/getCountByVillageId', getCountByVillageId);
  getCountByVillageId(body){
    return requests.post('/voter/getCountByVillageId',body); 
  },
  creteVoter(body){
    return requests.post('/voter/add',body); 
  },
  deleteVoter(body){
    return requests.delete(`/voter/${body._id}`); 
  },
  findVoterList(body){
    return requests.post(`/voter/find`,body); 
  },
  upadeVoter(body) {
    return requests.put(`/voter/${body._id}`,body); 
  },
  getVotersReport(body){
    return requests.post('/voter/reports',body); 
  },

};
export default VoterService;
