import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Constituency() {
    const tableHeaders = ['District', 'Constituency', 'Edit', 'Delete'];
    const classes = useStyles();
    const [districtList, setDistrictList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [tabelIdList, setTabelIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [constituency, setConstituency] = useState({
        districtId: '',
        constituency: '',
    });
    const validationSchema = Yup.object().shape({
        districtId: Yup.string().required('district is required'),
        constituency: Yup.string().required('constituency is required'),
        
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        getConstituencyList();
        return () => {
            setDistrictList([]);
            setConstituencyList([]);
        }
    }, []);
    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConstituencyList = () => {
        ConstituencyService.getAllConstituency().then((res) => {
            setConstituencyList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
     // const getSubActivityList = (event, obj) => {
    //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    //     const getsubActList = { schooleId: userDetails.schoolId, activityId: event.target.value };
    //     SubActivityService.getAllSubActivityByActivityId(getsubActList).then((res) => {
    //         setSubActivityList(res);
    //         if (obj) {
    //             setActivity(obj);
    //         }
    //     }).catch((err) => {
    //         // setError(err.message);
    //     });
    // }
    const handleOpen = () => {
        setOpen(true);
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
        const createObjectConstituency = {
            districtId: data.district,
            constituency: data.constituency,
        }
    };

    const editConstituency = (constituency) => {
        const obj = JSON.parse(JSON.stringify(constituency));
        
        obj.districtId = constituency.districtId ? constituency.districtId._id : '';
        //getDistrictList(obj.districtId);
        setConstituency(obj);
        handleOpen();
    }
    const deleteConstituency = (constituencydelete) => {
        if (constituencydelete) {
            ConstituencyService.deleteConstituency(constituencydelete).then((res) => {
                getConstituencyList();
            }).catch((err) => {
            });
        }
    };
    const handleClickOpen = (constituency) => {
        if (constituency && constituency.districtId) {
            setConstituency({
                district: constituency.districtId._id,
                constituency: constituency.constituency,
                id: constituency._id,

            })
        } else {
            formReset();
        }
        setOpen(true);
    };
    const selectDistrict = (event) => {
        const constituencySelectData = {
            district: event,
            constituency: constituency.constituency,
            id: constituency.id,

        }
        setConstituency(constituencySelectData)
    }
    const selectConstituency = (event) => {
        const constituencySelectData = {
            district: constituency.district,
            constituency: event.target.value,
            id: constituency.id,

        }
        setConstituency(constituencySelectData)

    }
    const formReset = () => {
        const constituencySelectData = {
            district: '',
            constituency: '',
            id: '',
        }
        setConstituency(constituencySelectData)
    }
    const formik = useFormik({
        initialValues: constituency,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (constituency._id) {
                ConstituencyService.upadeConstituencyt(values).then((res) => {
                    handleClose();
                    getConstituencyList();
                    resetForm()
                    alert("Constituency Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                ConstituencyService.creteConstituency(values).then((res) => {
                    getConstituencyList();
                    resetForm();
                    handleClose();
                    alert(" Constituency Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
            <PageTitle title="Constituency" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#30875b' }}> Add Constituency
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {constituencyList.slice(pg * rpg, pg * rpg + rpg).map((constituency) => (
                                    <TableRow key={constituency._id}>
                                        <TableCell className="pl-3 fw-normal" >
                                            {constituency.districtId.district}</TableCell>

                                        <TableCell className="pl-3 fw-normal" >{constituency.constituency}</TableCell>
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editConstituency(constituency)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteConstituency(constituency)} />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5,  100,1000]}
                            count={districtList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Constituency</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">District</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="districtId"
                                label="District"
                                type="text"
                                variant="standard"
                                value={formik.values.districtId}
                                onChange={formik.handleChange}
                                error={formik.touched.districtId && Boolean(formik.errors.districtId)}
                                helperText={formik.touched.districtId && formik.errors.districtId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {districtList.map(({ _id, district }) => (
                                    <MenuItem key={_id} value={_id}>{district}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="constituency"
                            name="constituency"
                            label="Constituency"
                            type="text"
                            variant="standard"
                            value={formik.values.constituency}
                            onChange={formik.handleChange}
                            error={formik.touched.constituency && Boolean(formik.errors.constituency)}
                            helperText={formik.touched.constituency && formik.errors.constituency}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


