import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell,
    Card
} from "@material-ui/core";
import Moment from 'moment';
import ExportExcel from "../../Excelexport";
import { Box } from "@material-ui/core";
import BoothService from "./Locality/Service/boothService";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
import VoterDataService from "./Locality/Service/voterDataService";
import CasteService from "./Locality/Service/casteService";
import PartyService from "./Locality/Service/partyService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function VoterData() {
    const tableHeaders = ['Village', 'Booth Number', 'Name', 'Livies In Villege ', 'Family Size ', 'Mobile Number',
        'Caste', 'Party', 'Status', 'Date', 'Edit', 'Delete', 'Add Family',];
    const classes = useStyles();
    const [count, setCount] = useState([]);
    const [family, setFamily] = useState({});
    const [districtList, setDistrictList] = useState([]);
    const [casteList, setCasteList] = useState([]);
    const [partyList, setPartyList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    const [voterDataList, setVoterDataList] = useState([]);
    const [voterfinalDataList, setfinalVoterDataList] = useState({
        headers: [],
        data:[]
    });

    const [boothList, setBoothList] = useState([]);
    var [error, setError] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [familyOpen, setFamilyOpen] = React.useState(false);
    const [familycount, setFamilyCount] = React.useState(0);

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    var [partyId, setPartyId] = useState("");
    var [casteId, setCasteId] = useState("");
    const [result, setResult] = useState([]);
    const [isDisableForm, setIsDisableForm] = useState(false);

    const [partyIdList, setPartyIdList] = useState("");
    const [casteIdList, setCasteIdList] = useState("");

    const [voterData, setVoterData] = useState({
        districtId: '',
        constituencyId: '',
        villageId: '',
        countId: '',
        name: '',
        livies: '',
        family: '',
        mobile: '',
        casteId: '',
        partyId: '',
        status: '',
    });
    const validationSchema = Yup.object().shape({
        districtId: Yup.string().required('district is required'),
        constituencyId: Yup.string().required('constituency is required'),
        villageId: Yup.string().required('village is required'),
        countId: Yup.string().required('count is required'),
        name: Yup.string().required('name is required'),
        livies: Yup.string().required('livies in villege is required'),
        family: Yup.string().required('family no is required'),
        mobile: Yup.string().required()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits'),
        casteId: Yup.string().required('caste is required'),
        partyId: Yup.string().required('party is required'),
        status: Yup.string().required('status is required'),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        getVoterDataList();
        getCasteList();
        getPartyList();
        onSubmit();
        
        return () => {
            setDistrictList([]);
            setConstituencyList([]);
            setVillageList([]);
            setVoterDataList([]);
            setPartyList([]);
            setPartyIdList([]);
            setCasteIdList([]);
        }
    }, []);
    const excelExport = (res) => {
        const result = res.map((response) => {
            return {
                "District": response.districtId ? response.districtId.district : '',
                "Constituency": response.constituencyId ? response.constituencyId.constituency : '',
                "Village": response.villageId ? response.villageId.village : '',
                "Booth": response.countId ? response.countId.count : '',
                "Name": response.name,
                "Livies In Villege ": response.livies,
                "Family Size ": response.family,
                "Mobile Number": response.mobile,
                "Caste": response.casteId ? response.casteId.caste : '',
                "Party": response.partyId ? response.partyId.party : '',
                "Status": response.status,
            }
        })
        setResult(result);
    };
    const getPartyIdList = (event, obj) => {
        PartyService.findPartyList().then((res) => {
            const partyDetails = res.map(res => {
                return { _id: res._id, party: res.party, status: true };
            })
            setPartyList(partyDetails);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getBoothById =()=>{
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        if(userDetails && userDetails._id){
            BoothService.getBoothById(userDetails._id).then((res) => {
const patchData = {};
patchData.countId = res._id ;
patchData.constituencyId = res.constituencyId;
patchData.districtId = res.districtId ;
patchData.villageId = res.villageId;

        getConstituencyList(res.districtId);
        getVillageList(res.constituencyId);
        getBoothList(res.villageId);
        setVoterData({...voterData,...patchData});
        setIsDisableForm(true);
            }).catch((err) => {
                setError(err.message);
            });
        }
    }
    const partyConvert = (partId) => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        
        if (userDetails.partyId === partId) {

            setVoterData({ ...formik.values, ...{ status: "Converted", partyId: partId } });

        } else {
            setVoterData({ ...formik.values, ...{ status: "", partyId: partId } });
        }

    }
    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConstituencyList = (destId) => {
        const disListI = { districtId: destId };
        ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {
            setConstituencyList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVillageList = (constituencyId) => {
        const villListId = { constituencyId: constituencyId };
        VillageService.getVillageByConstituencyId(villListId).then((res) => {
            setVillageList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    
    const handleOpenCreate  = () => {
        setOpen(true);
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        if (userDetails && userDetails.role === 'BOOTH') {
            getBoothById();
        }
       
    };
    const handleOpen = () => {
        setOpen(true);
       // getBoothById();
    };
    const familyHandleOpen = () => {
        setFamilyOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const familyHandleClose = () => {
        setFamilyOpen(false);
    };
    const onSubmit = data => {
        const keys = { "partyId": partyId, "casteId": casteId }
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        if (userDetails && userDetails.role === 'BOOTH') {
           // getBoothById();
           keys.countId=userDetails._id;
        }
        VoterDataService.findVoterDataList(keys).then((res) => {
            const updatedData = res.map(item => {

                return { ...item, date: dateAndTime(item.createdAt) };
            });
            
            const sum = updatedData.reduce((accumulator, currentValue) => {
                return accumulator + Number(currentValue.family);
              }, 0);
              setFamilyCount(sum);
            excelExport(res);
            setVoterDataList(updatedData);
        }).catch((err) => {
            console.error(err)
            // setError(err.message);
        });

    };

    const getUniqKeys =(arrayOfObjects)=>{
        const uniqueKeys = new Set();

arrayOfObjects.forEach(obj => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      uniqueKeys.add(key);
    }
  }
});

 return Array.from(uniqueKeys);
    }
    const getVoterDataList = () => {
        let postData = {}; 
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        if (userDetails && userDetails.role === 'BOOTH') {
           // getBoothById();
           postData.countId=userDetails._id;
        }
        VoterDataService.findVoterDataList(postData).then((res) => {
            const updatedData = res.map(item => {
                return { ...item, date: dateAndTime(item.createdAt) };
            });
            const groupedData = {};
           updatedData.forEach(item => {
                const partyId = item.countId.count;
                if (!groupedData[partyId]) {
                    groupedData[partyId] = [];
                }
                const finalData= {
                    family:Number(item.family),
                    partyId:item.partyId?item.partyId._id:'',
                    party:item.partyId?item.partyId.party:'',
                    totalVotars:item.voterId?Number(item.voterId.voter):'',
                };
                groupedData[partyId].push(finalData);
            });
            const setFinalData = {
                headers: '',
                data:[]
            }
            const sumByParty = [];
            for (const booth in groupedData) {
                if (groupedData.hasOwnProperty(booth)) {
                  const data = groupedData[booth];
                  console.log(`Booth: ${booth}`);
                  const result = {};
                  data.forEach(item => {
                    const party = item.party;
                    const family = item.family;
                
                    if (!result[party]) {
                      result[party] = 0;
                    }
                
                    result[party] += family;
                  });
                  sumByParty.push({
                    'Booth Name':booth,
                    'Total Votes' :data.length ? data[0].totalVotars:0,
                    ...result,
                  });
                  setFinalData.headers =sumByParty.length ? getUniqKeys(sumByParty):[];
                }
              }
              const propertiesToSum =  setFinalData.headers;
              const sum = sumByParty.reduce((total, item) => {
                propertiesToSum.forEach(property => {
                    total[property] = (total[property] || 0) + (item[property] || 0);
                });
                return total;
            }, {});
            sum['Booth Name'] = 'Total';
            setFinalData.data = [...sumByParty,...[sum]];
        //    setFinalData.data = [...sumByParty];
        setFinalData.headers=setFinalData.headers? setFinalData.headers:[];
            setfinalVoterDataList(setFinalData)
        });
    }
    const dateAndTime = (date) => {

        const systemDate = new Date(date);
        const formattedDateTime = systemDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            // hour: 'numeric',
            // minute: 'numeric',
            // second: 'numeric',
            // hour12: true,

        });
        return formattedDateTime;
    }


    const getCasteList = () => {
        CasteService.getAllCaste().then((res) => {
            setCasteList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getPartyList = () => {
        PartyService.getAllParty().then((res) => {
            setPartyList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const editVoterData = (voterData) => {
        const obj = JSON.parse(JSON.stringify(voterData));

        obj.countId = voterData.countId ? voterData.countId._id : '';
        obj.constituencyId = voterData.constituencyId ? voterData.constituencyId._id : '';
        obj.districtId = voterData.districtId ? voterData.districtId._id : '';
        obj.villageId = voterData.villageId ? voterData.villageId._id : '';
        obj.casteId = voterData.casteId ? voterData.casteId._id : '';
        obj.partyId = voterData.partyId ? voterData.partyId._id : '';
        getConstituencyList(obj.districtId);
        getVillageList(obj.constituencyId);
        getBoothList(obj.villageId);
        setVoterData(obj);
        handleOpen();
    }
    const deleteVoterData = (voterdatadelete) => {
        if (voterdatadelete) {
            VoterDataService.deleteVoterData(voterdatadelete).then((res) => {
                getVoterDataList();
            }).catch((err) => {
            });
        }
    };

    const addFamily = (family) => {
        const familyList = [];
        let familyCount = family.familyDetails.length > 0 ? family.familyDetails.length : Number(family.family);

        for (let i = 0; i < familyCount; i++) {
            const nameDetails = family.familyDetails.length > 0 ? family.familyDetails[i].name : '';
            familyList.push({ name: nameDetails, isAttend: false });
        }
        setFamily(family);
        setCount(familyList);
        familyHandleOpen();
    };
    const familyHandleChange = (e, index) => {
        const value = e.target.value;
        const familyList = count;
        familyList[index] = { name: value, isAttend: false };
        setCount(familyList);
    };
    const formik = useFormik({
        initialValues: voterData,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (voterData._id) {
                VoterDataService.upadeVoterData(values).then((res) => {
                    handleClose();
                    getVoterDataList();
                    resetForm()
                    alert(" Voter Data Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                VoterDataService.creteVoterData(values).then((res) => {
                    getVoterDataList();
                    resetForm();
                    handleClose();
                    alert("Voter Data Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }

        },
    });
    const getBoothList = (villageId) => {
        
        const boothListId = { villageId: villageId };
        BoothService.getBoothByVillageId(boothListId).then((res) => {

            setBoothList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const familyHandleSubmit = () => {

        VoterDataService.upadeVoterData({ isFamilyDetailsUpdate: true, _id: family._id, familyDetails: count }).then((res) => {
            familyHandleClose();
            alert(" Voter Data Updated Successfully.");
        }).catch((err) => {
        });
    }
    return (
        <>
            <PageTitle title="Party Wise Voters Data"/>
          
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {voterfinalDataList.headers.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {voterfinalDataList.data.slice(pg * rpg, pg * rpg + rpg).map((report,index) => (
                                        <TableRow key={index}>

                                            {voterfinalDataList.headers.map(key => (
                                                <TableCell className="pl-3 fw-normal" >{report[key] ? report[key] : 0}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                              
                             
                            </TableBody>
                        </Table>
                    <div>

                    </div>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 50, 1000, 2000]}
                            count={voterfinalDataList.data.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Family Wise Voters Data</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">District</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="districtId"
                                label="District"
                                type="text"
                                disabled={isDisableForm}
                                variant="standard"
                                value={formik.values.districtId}
                                // getConstituencyList();
                                onChange={e => {
                                    formik.handleChange(e);

                                    getConstituencyList(e.target.value)
                                }}
                                error={formik.touched.districtId && Boolean(formik.errors.districtId)}
                                helperText={formik.touched.districtId && formik.errors.districtId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {districtList.map(({ _id, district }) => (
                                    <MenuItem key={_id} value={_id}>{district}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Constituency </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                margin="dense"
                                name="constituencyId"
                                label="Constituency"
                                disabled={isDisableForm}
                                type="text"
                                variant="standard"
                                value={formik.values.constituencyId}
                                onChange={e => {
                                    formik.handleChange(e);
                                    getVillageList(e.target.value)
                                }}
                                error={formik.touched.constituencyId && Boolean(formik.errors.constituencyId)}
                                helperText={formik.touched.constituencyId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {constituencyList.map(({ _id, constituency }) => (
                                    <MenuItem key={_id} value={_id}>{constituency}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Village </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                disabled={isDisableForm}
                                margin="dense"
                                name="villageId"
                                label="Village"
                                type="text"
                                variant="standard"
                                value={formik.values.villageId}
                                onChange={e => {
                                    formik.handleChange(e);
                                    getBoothList(e.target.value)
                                }}
                                error={formik.touched.villageId && Boolean(formik.errors.villageId)}
                                helperText={formik.touched.villageId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {villageList.map(({ _id, village }) => (
                                    <MenuItem key={_id} value={_id}>{village}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Booth </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                disabled={isDisableForm}
                                margin="dense"
                                name="countId"
                                label="Booth"
                                type="text"
                                variant="standard"
                                value={formik.values.countId}
                                onChange={formik.handleChange}
                                error={formik.touched.countId && Boolean(formik.errors.countId)}
                                helperText={formik.touched.countId && formik.errors.countId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {boothList.map(({ _id, count }) => (
                                    <MenuItem key={_id} value={_id}>{count}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Name"
                            type="text"
                            variant="standard"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />

                        <FormControl className={classes.formControl}
                            fullWidth="true" >
                            <InputLabel id="demo-simple-select-label">Lives In Village</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="livies"
                                name="livies"
                                label="Lives In Village"
                                onChange={formik.handleChange}
                                value={formik.values.livies}
                                error={formik.touched.livies && Boolean(formik.errors.livies)}
                                helperText={formik.touched.livies && formik.errors.livies}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                <MenuItem value={"No"}>No</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            margin="dense"
                            id="family"
                            name="family"
                            label="Family Size"
                            type="text"
                            variant="standard"
                            value={formik.values.family}
                            onChange={formik.handleChange}
                            error={formik.touched.family && Boolean(formik.errors.family)}
                            helperText={formik.touched.family && formik.errors.family}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            margin="dense"
                            id="mobile"
                            name="mobile"
                            label="Mobile Number"
                            type="text"
                            variant="standard"
                            value={formik.values.mobile}
                            onChange={formik.handleChange}
                            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                            helperText={formik.touched.mobile && formik.errors.mobile}
                        />
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Caste</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                
                                margin="dense"
                                name="casteId"
                                label="Caste"
                                type="text"
                                variant="standard"
                                value={formik.values.casteId}
                                // getConstituencyList();
                                onChange={formik.handleChange}
                                error={formik.touched.casteId && Boolean(formik.errors.casteId)}
                                helperText={formik.touched.casteId && formik.errors.casteId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {casteList.map(({ _id, caste }) => (
                                    <MenuItem key={_id} value={_id}>{caste}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Party</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                               
                                margin="dense"
                                name="partyId"
                                label="Party"
                                type="text"
                                variant="standard"
                                value={formik.values.partyId}
                                // getConstituencyList();
                                onChange={e => {
                                    formik.handleChange(e);
                                    getPartyIdList(e.target.value);
                                    partyConvert(e.target.value);
                                }}
                                error={formik.touched.partyId && Boolean(formik.errors.partyId)}
                                helperText={formik.touched.partyId && formik.errors.partyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {partyList.map(({ _id, party }) => (
                                    <MenuItem key={_id} value={_id}>{party}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}
                            fullWidth="true" >
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="status"
                                name="status"
                                label="Status"
                                onChange={e => {
                                    formik.handleChange(e);
                                }}
                                value={formik.values.status}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                helperText={formik.touched.status && formik.errors.status}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Converted"}>Converted</MenuItem>
                                <MenuItem value={"Under Process"}>Under Process</MenuItem>
                                <MenuItem value={"Not interested"}>Not Interested</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={familyOpen} onClose={familyHandleClose}>
                <DialogTitle>Add Family Voters</DialogTitle>

                <DialogContent style={{ width: 308 }}>
                    {count.map((key, index) => (
                        key.name ? <TextField
                            InputProps={{ style: { width: 258 } }}
                            disabled={true}
                            margin="dense"
                            id="name"
                            name="name"
                            label="Enter Name"
                            type="text"
                            value={key.name}
                            variant="standard"

                        /> : <TextField
                            InputProps={{ style: { width: 258 } }}

                            margin="dense"
                            id="name"
                            name="name"
                            label="Enter Name"
                            type="text"

                            variant="standard"
                            onChange={(e) => familyHandleChange(e, index)}


                        />
                    ))}

                </DialogContent>
                <DialogActions>
                    <Button onClick={familyHandleClose}>Cancel</Button>
                    <Button type="button" onClick={familyHandleSubmit}>Add</Button>
                </DialogActions>

            </Dialog>
        </>
    );
}


