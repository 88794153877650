import React, { useState } from "react";
import {
    Button,  FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    Card,
    TableCell,
} from "@material-ui/core";
import { Box } from "@material-ui/core";
import ExportExcel from "../../Excelexport";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
import VoterService from "./Locality/Service/voterService";
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select } from "@material-ui/core";
import { useEffect } from 'react';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function VoterReport() {
    const tableHeaders = [ 'Village','Booth','Number Of Voters In Village', 'Percentage', 'Cumulative Percentage'];
    const classes = useStyles();
    var [constituencyId, setConstituencyId] = useState("");
    const [voterList, setVoterList] = useState([]); 
    const [districtList, setDistrictList] = useState([]);
       const [constituencyList, setConstituencyList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    var [constituencyId, setConstituencyId] = useState("");
    var [error, setError] = useState(null);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(50);
    const [result, setResult]= useState([]);
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getVillageList();
        // getVoterList();
        getDistrictList();

        return () => {
            setDistrictList([]);
            setConstituencyList([]);
            // setRepresentiveContactList([]);
            setVillageList([]);
            setVoterList({});
            
        }
    }, []);
  
    const getVillageList = (event,obj) => {
        VillageService.getAllVillage().then((res) => {
            setVillageList(res);
           
        }).catch((err) => {
            setError(err.message);
        });
    }


    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConstituencyList = (destId) => {
      const disListI = { districtId: destId };
        ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {
           
            setConstituencyList(res);
           
        }).catch((err) => {
            setError(err.message);
        });
    }
    const onSubmit = data => {
        const keys = {    "constituencyId":constituencyId}
        VoterService.getVotersReport(keys).then((res) => {
          
            //  let sortedTotalVotes = res.slice().sort((a, b) => b.totalVotes - a.totalVotes);
            
            const roundOf = res.returnvalues.map(res => {
                return { _id: res._id,votePercetage: res.votePercetage.toFixed(2), cumilativePer: res.cumilativePer.toFixed(2),
                    totalVotes:res.totalVotes, villageName:res.villageName, boothCount:res.boothCount,
                     totalVotesInConst:res.totalVotesInConst,  };
            })
            res.returnvalues =roundOf;
            setVoterList(res.returnvalues || []);
            excelExport(res.returnvalues || []);
        }).catch((err) => {
            console.error(err)
        });

    };
    const  excelExport  =(res)  => {
        const result= res.map((response)=>{
             return {
                
                 "Village Name":response.villageName , 
                 "Both Count": response. boothCount,
                 "Number Of Voters In Village": response.totalVotes,
                 "Number Of Voters In Constituency ": response.totalVotesInConst,
                 "Percentage":response.votePercetage,
                 "Cumulative Percentage":response.cumilativePer,
             }
            
          })
          setResult(result);
     };
    return (
        <>
                <PageTitle title="Voters Statistics"  />
            <Card sx={{ maxWidth: 345 }}>
                <Box   >
                    <div >
                        <form >
                            <Grid container spacing={2} columns={12} style={{ margin: 10 }}  >
                                <Grid item xs={12} sm={3} md={3} >
                                <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">District</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="districtId"
                                label="District"
                                type="text"
                                variant="standard"
                                onChange={e => {getConstituencyList(e.target.value)}}
                                
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {districtList.map(({ _id, district }) => (
                                    <MenuItem key={_id} value={_id}>{district}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} >
                                <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Constituency </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                margin="dense"
                                name="constituencyId"
                                label="Constituency"
                                type="text"
                                variant="standard"
                                value={constituencyId}
                                onChange={e => { setConstituencyId(e.target.value) }}
                                // onChange={e => {
                                    
                                //     getVillageList(e.target.value)
                                // }}
                             
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {constituencyList.map(({ _id, constituency }) => (
                                    <MenuItem key={_id} value={_id}>{constituency}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                                </Grid>
                                
                               
                                <Grid item xs={12}  sm={3} md={3}>
                                    <Button style={{ backgroundColor: 'rgb(48 135 91)', color: 'white' }}
                                        type="button"
                                        onClick={() => onSubmit()} variant="contained"
                                    >
                                        Search</Button>
                                </Grid>
                                <Grid item xs={3} >
                                <ExportExcel excelData={result} fileName={'Voters Percentage'} />
                                </Grid>
                              
                            </Grid>
                        </form>
                    </div>
                </Box>
            </Card>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {voterList.slice(pg * rpg, pg * rpg + rpg).map((voter) => (
                                    <TableRow key={voter._id}>
                                        {/* <TableCell className="pl-3 fw-normal" >{representivecontact.districtId.district}</TableCell> */}
                                        <TableCell className="pl-3 fw-normal" >{voter.villageName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voter.boothCount}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voter.totalVotes}</TableCell>
                                        {/* <TableCell className="pl-3 fw-normal" >{voter.totalVotesInConst}</TableCell> */}
                                        <TableCell className="pl-3 fw-normal" >{voter.votePercetage}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voter.cumilativePer}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                     
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[ 50,  75, 100, 500, 1000]}
                            count={voterList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
          
        </>
    );
}


