import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    Card,
    TableCell,
} from "@material-ui/core";
import { Box } from "@material-ui/core";
import RepresentiveContactService from "./Locality/Service/representiveContact";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
import BoothService from "./Locality/Service/boothService";
import VoterService from "./Locality/Service/voterService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function RepresentiveReports() {
    const tableHeaders = [ 'Village','Booth','Name','Caste','Party','Ward','Designation'];
    const classes = useStyles();
    const [districtList, setDistrictList] = useState([]);
    var [constituencyId, setConstituencyId] = useState("");
    const [representiveContactList, setRepresentiveContactList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    const [voterList, setVoterList] = useState([]);
    const [boothList, setBoothList] = useState([]);
    const [age, setAge] = React.useState('');
    var [villageId, setVillageId] = useState("");
    var [casteId, setCasteId] = useState("");
    var [partyId, setPartyId] = useState("");
    var [error, setError] = useState(null);
    
    const [tabelIdList, setTabelIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [activityIdList, setActivityIdList] = useState([]);
    const [representiveContact, setRepresentiveContact] = useState({
        districtId: '',
        constituencyId: '',
        villageId:'',
        countId:'',
        voter:'',
    });
    const validationSchema = Yup.object().shape({
        districtId: Yup.string().required('district is required'),
        constituencyId: Yup.string().required('constituency is required'),
        villageId: Yup.string().required('village is required'),
        countId:Yup.string().required('count is required'),
        voter:Yup.string().required('count is required'),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getVillageList();
        getVoterList();
       
        getDistrictList();
        getRepresentiveContactList();
        return () => {
            setRepresentiveContactList([]);
            setVillageList([]);
            setVoterList([]);
            setDistrictList([]);
            setConstituencyList([]);
        }
    }, []);
    const getRepresentiveContactList = () => {
        RepresentiveContactService.getAllRepresentiveContact().then((res) => {
            setRepresentiveContactList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVillageList = (event,obj) => {
        VillageService.getAllVillage().then((res) => {
            setVillageList(res);
           
        }).catch((err) => {
            setError(err.message);
        });
    }

    const getVoterList = () => {
        VoterService.getAllVoter().then((res) => {
            setVoterList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const getConstituencyList = (destId) => {
        const disListI = { districtId: destId };
          ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {
             
              setConstituencyList(res);
             
          }).catch((err) => {
              setError(err.message);
          });
      }
      const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const onSubmit = data => {
        const keys = {   "villageId": villageId, "casteId":casteId, "partyId":partyId}
        RepresentiveContactService.findRepresentiveContactList(keys).then((res) => {
            // excelExport(res);
            setRepresentiveContactList(res);
        }).catch((err) => {
            console.error(err)
            // setError(err.message);
        });

    };
    return (
        <>
                <PageTitle title="Representive Reports"  />
            <Card sx={{ maxWidth: 345 }}>
                <Box   >
                    <div >
                        <form >
                            <Grid container spacing={2} columns={12} style={{ margin: 10 }}  >
                            <Grid item xs={12} sm={3} md={3} >
                                <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">District</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="districtId"
                                label="District"
                                type="text"
                                variant="standard"
                                onChange={e => {getConstituencyList(e.target.value)}}
                                
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {districtList.map(({ _id, district }) => (
                                    <MenuItem key={_id} value={_id}>{district}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} >
                                <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Constituency </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                margin="dense"
                                name="constituencyId"
                                label="Constituency"
                                type="text"
                                variant="standard"
                                value={constituencyId}
                                onChange={e => { setConstituencyId(e.target.value) }}
                               
                             
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {constituencyList.map(({ _id, constituency }) => (
                                    <MenuItem key={_id} value={_id}>{constituency}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} >
                                <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Village </InputLabel>
                            <Select
                            InputProps={{ style: { width: 258 } }}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                margin="dense"
                                name="villageId"
                                label="Village"
                                type="text"
                                variant="standard"
                                value={villageId}
                                onChange={e => { setVillageId(e.target.value) }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {villageList.map(({ _id, village }) => (
                                    <MenuItem key={_id} value={_id}>{village}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                                </Grid>
                          
                                <Grid item xs={12}  sm={3} md={63}>
                                    <Button style={{ backgroundColor: 'rgb(48 135 91)', color: 'white' }}
                                        type="button"
                                        onClick={() => onSubmit()} variant="contained"
                                    >
                                        Search</Button>
                                </Grid>
                               
                            </Grid>
                        </form>
                    </div>
                </Box>
            </Card>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {representiveContactList.slice(pg * rpg, pg * rpg + rpg).map((representivecontact) => (
                                    <TableRow key={representivecontact._id}>
                                        {/* <TableCell className="pl-3 fw-normal" >{representivecontact.districtId.district}</TableCell> */}
                                        <TableCell className="pl-3 fw-normal" >{representivecontact.villageId?.village}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{representivecontact.significance}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{representivecontact.name}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{representivecontact.casteId?.caste}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{representivecontact.partyId?.party}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{representivecontact.significance}</TableCell>                                         
                                        <TableCell className="pl-3 fw-normal" >{representivecontact.age}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 50,  75, 100, 500, 1000]}
                            count={representiveContactList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
          
        </>
    );
}


