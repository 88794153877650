import requests from './httpService';
const TrackVotersService = {
  getAllTrackVoters(countId) {
    return requests.get(`/trackvoters/list/${countId}`);
  },
  getConstituencyByDistrictId(body){
    return requests.post('/trackvoters/getConstituencyByDistrictId',body); 
  },
  getVillageByConstituencyId(body){
    return requests.post('/trackvoters/getVillageByConstituencyId',body); 
  },
  getBoothByVillageId(body){
    return requests.post('/trackvoters/getBoothByVillageId',body); 
  },
  getCountByVillageId(body){
    return requests.post('/trackvoters/getCountByVillageId',body); 
  },
  creteTrackVoters(body){
    return requests.post('/trackvoters/add',body); 
  },
  deleteTrackVoters(body){
    return requests.delete(`/trackvoters/${body._id}`); 
  },
  findTrackVotersList(body){
    return requests.post(`/trackvoters/find`,body); 
  },
  upadeTrackVoters(body) {
    return requests.put(`/trackvoters/${body._id}`,body); 
  },
  getVotersByBoothId(body){
    return requests.post('/trackvoters/getVotersByBoothId',body); 
  },
};
export default TrackVotersService;
