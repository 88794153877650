import requests from './httpService';
const RepresentiveContactService = {
  getAllRepresentiveContact() {
    return requests.get(`/representivecontact/list`);
  },
  
  addRepresentiveContactByConstituenceyId(body){
    return requests.post(`/representivecontact/addRepresentiveContactByConstituenceyId`,body);
  },
  getConstituencyByDistrictId(body){
    return requests.post('/representivecontact/getConstituencyByDistrictId',body); 
  },
  getVillageByConstituencyId(body){
    return requests.post('/representivecontact/getVillageByConstituencyId',body); 
  },
  getBoothByVillageId(body){
    return requests.post('/representivecontact/getBoothByVillageId',body); 
  },
  getCountByVillageId(body){
    return requests.post('/representivecontact/getCountByVillageId',body); 
  },
  creteRepresentiveContact(body){
    return requests.post('/representivecontact/add',body); 
  },
  deleteRepresentiveContact(body){
    return requests.delete(`/representivecontact/${body._id}`); 
  },
   findRepresentiveContactList(body){
    return requests.post(`/representivecontact/find`,body); 
  },
  findRepresentiveContactList(body){
    return requests.post(`/representivecontact/find`,body); 
  },
  upadeRepresentiveContact(body) {
    return requests.put(`/representivecontact/${body._id}`,body); 
  },
};
export default RepresentiveContactService;
