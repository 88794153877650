import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell,
    Card
} from "@material-ui/core";
import ExportExcel from "../../Excelexport";
import { Box } from "@material-ui/core";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
import VoterDataService from "./Locality/Service/voterDataService";
import CasteService from "./Locality/Service/casteService";
import PartyService from "./Locality/Service/partyService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function OurVoter() {
    const tableHeaders = ['Village', 'Booth Number', 'Name', 'Livies In Villege ', 'Family Size ', 'Mobile Number', 'Names'];
    const classes = useStyles();
    const [count, setCount] = useState([]);
    const [family, setFamily] = useState({});
    const [districtList, setDistrictList] = useState([]);
    const [casteList, setCasteList] = useState([]);
    const [partyList, setPartyList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    const [voterDataList, setVoterDataList] = useState([]);
    var [error, setError] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [familyOpen, setFamilyOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    var [partyId, setPartyId] = useState("");
    var [casteId, setCasteId] = useState("");
    const [result, setResult] = useState([]);
    const [partyIdList, setPartyIdList] = useState("");
    const [casteIdList, setCasteIdList] = useState("");
    const [nameList, setNameList] = useState([]);
    const [voterData, setVoterData] = useState({
        // districtId: '',
        // constituencyId: '',
        // villageId:'',
        name: '',
        livies: '',
        family: '',
        mobile: '',
        casteId: '',
        partyId: '',
        status: '',
    });
    const validationSchema = Yup.object().shape({
        // districtId: Yup.string().required('district is required'),
        // constituencyId: Yup.string().required('constituency is required'),
        // villageId: Yup.string().required('village is required'),
        name: Yup.string().required('name is required'),
        livies: Yup.string().required('livies in villege is required'),
        family: Yup.string().required('family no is required'),
        mobile: Yup.string().required()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits'),
        casteId: Yup.string().required('caste is required'),
        partyId: Yup.string().required('party is required'),
        status: Yup.string().required('status is required'),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        getVoterDataList();
        getCasteList();
        getPartyList();
       // onSubmit();
        return () => {
            setDistrictList([]);
            setConstituencyList([]);
            setVillageList([]);
            setVoterDataList([]);
            setPartyList([]);
            setPartyIdList([]);
            setCasteIdList([]);
        }
    }, []);
    const excelExport = (res) => {
        const result = res.map((response) => {
            return {
                "Name": response.name,
                "Livies In Villege ": response.livies,
                "Family Size ": response.family,
                "Mobile Number": response.mobile,
                "Caste": response.casteId ? response.casteId.caste : '',
                "Party": response.partyId ? response.partyId.party : '',
                "Status": response.status,
            }

        })
        setResult(result);
    };
    const getPartyIdList = (event, obj) => {
        PartyService.findPartyList().then((res) => {
            const partyDetails = res.map(res => {
                return { _id: res._id, party: res.party, status: true };
            })
            setPartyList(partyDetails);
        }).catch((err) => {
            // setError(err.message);
        });
    }

    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConstituencyList = (destId) => {
        const disListI = { districtId: destId };
        ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {
            setConstituencyList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVillageList = (constituencyId) => {
        const villListId = { constituencyId: constituencyId };
        VillageService.getVillageByConstituencyId(villListId).then((res) => {
            setVillageList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const familyHandleOpen = () => {
        setFamilyOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const familyHandleClose = () => {
        setFamilyOpen(false);
    };
    const onSubmit = data => {
        const keys = { "partyId": partyId, "casteId": casteId }
        VoterDataService.findVoterDataList(keys).then((res) => {
            excelExport(res);
            setVoterDataList(res);
        }).catch((err) => {
            console.error(err)
            // setError(err.message);
        });

    };
    const getVoterDataList = () => {
        let filterData= {};
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        
        if(userDetails && userDetails.role === 'ADMIN'){
            filterData = {countId:''};
        }else{
            filterData = {countId:userDetails._id};
        }
        
        VoterDataService.findVoterDataList(filterData).then((res) => {
            setVoterDataList(res);
            
            //excelExport(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getCasteList = () => {
        CasteService.getAllCaste().then((res) => {
            setCasteList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getPartyList = () => {
        PartyService.getAllParty().then((res) => {
            setPartyList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const editVoterData = (voterData) => {
        const obj = JSON.parse(JSON.stringify(voterData));
        obj.constituencyId = voterData.constituencyId ? voterData.constituencyId._id : '';
        obj.districtId = voterData.districtId ? voterData.districtId._id : '';
        obj.villageId = voterData.villageId ? voterData.villageId._id : '';
        obj.casteId = voterData.casteId ? voterData.casteId._id : '';
        obj.partyId = voterData.partyId ? voterData.partyId._id : '';
        getConstituencyList(obj.districtId);
        getVillageList(obj.constituencyId);
        setVoterData(obj);
        handleOpen();
    }
    const deleteVoterData = (voterdatadelete) => {
        if (voterdatadelete) {
            VoterDataService.deleteVoterData(voterdatadelete).then((res) => {
                getVoterDataList();
            }).catch((err) => {
            });
        }
    };

    const addFamily = (family) => {
        const familyList = [];
        let familyCount = family.familyDetails.length > 0 ? family.familyDetails.length : Number(family.family);

        for (let i = 0; i < familyCount; i++) {
            const nameDetails = family.familyDetails.length > 0 ? family.familyDetails[i].name : '';
            familyList.push({ name: nameDetails });
        }
        setFamily(family);
        setCount(familyList);
        familyHandleOpen();
    };
    const familyHandleChange = (e, index) => {
        const value = e.target.value;
        const familyList = count;
        familyList[index] = { name: value };
        setCount(familyList);


    };
    const formik = useFormik({
        initialValues: voterData,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (voterData._id) {
                VoterDataService.upadeVoterData(values).then((res) => {
                    handleClose();
                    getVoterDataList();
                    resetForm()
                    alert(" Voter Data Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                VoterDataService.creteVoterData(values).then((res) => {
                    getVoterDataList();
                    resetForm();
                    handleClose();
                    alert("Voter Data Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }

        },
    });
    const familyHandleSubmit = () => {
        VoterDataService.upadeVoterData({ isFamilyDetailsUpdate: true, _id: family._id, familyDetails: count }).then((res) => {
            familyHandleClose();
            alert(" Voter Data Updated Successfully.");
        }).catch((err) => {
        });
    }

    const familyHandleCheck = (voterData, findex) => {
        var updatedList = (voterData.familyDetails || []).map((res, index) => {
            if (findex === index) {
                return { ...res, isAttend: !res.isAttend };
            }
            return { ...res };

        });
        // console.log(updatedList);
        // setNameList(updatedList);

        console.log(findex)
        voterData.familyDetails[findex].isAttend = !voterData.familyDetails[findex].isAttend;
        console.log(voterData.familyDetails);
        VoterDataService.upadeVoterData({ isFamilyDetailsUpdate: true, _id: voterData._id, familyDetails: voterData.familyDetails }).then((res) => {
            familyHandleClose();
            getVoterDataList();
            //  alert(" Voter Data Updated Successfully.");
        }).catch((err) => {
        });
    };
    return (
        <>
            <PageTitle title="Voter Data" />
            {/* <Card sx={{ maxWidth: 345 }}>
                <Box   >
                    <div >
                        <form >
                            <Grid container spacing={3} columns={12} style={{ margin: 10 }}  >
                                <Grid item xs={3}  sm={3} md={3}>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel id="party">Party</InputLabel>
                                        <Select
                                            labelId="party"
                                            id="party"
                                            label="Party"
                                            name="partyId"
                                            value={partyId}
                                            onChange={e => { setPartyId(e.target.value) }}
                                        // error={formik.touched.studentId && Boolean(formik.errors.studentId)}
                                        // helperText={formik.touched.studentId && formik.errors.studentId}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {partyList.map(({ index, _id, party }) => (
                                                <MenuItem key={index} value={_id}>{party}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3}>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel id="caste">Caste</InputLabel>
                                        <Select
                                            labelId="caste"
                                            id="caste"
                                            label="Caste"
                                            name="casteId"
                                            value={casteId}
                                            onChange={e => {getPartyList(e.target.value); setCasteId(e.target.value) }}          
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {casteList.map(({ index, _id, caste }) => (
                                                <MenuItem key={index} value={_id}>{caste}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} >
                                    <Button style={{ backgroundColor: 'rgb(48 135 91)', color: 'white' }}
                                        type="button"
                                        onClick={() => onSubmit()} variant="contained"
                                    >
                                        Search</Button>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} >
                                    <ExportExcel excelData={result} fileName={'Voter Data'} />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Box>
            </Card> */}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {voterDataList.slice(pg * rpg, pg * rpg + rpg).map((voterData) => (
                                    <TableRow key={voterData._id}>
                                        <TableCell className="pl-3 fw-normal" >{voterData.villageId?.village}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voterData.countId?.count}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voterData.name}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voterData.livies ? 'Yes' : 'No'}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voterData.family}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voterData.mobile}</TableCell>

                                        <TableCell className="pl-3 fw-normal" >{(voterData?.familyDetails || []).map((key, findex) => (

                                            <Grid container rowSpacing={1} style={{ lineHeight: "2" }}>
                                                <Grid item xs={12}>
                                                     <input type="checkbox" checked={key.isAttend}
                                                    disabled={key.isAttend}
                                                    style={{ height: "15px", width: "15px" }}
                                                    onChange={e => familyHandleCheck(voterData, findex)}
                                                />
                                                    <span>{key.name}



                                                    </span>
                                                </Grid>
                                            </Grid>
                                        )
                                        )}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 50, 500, 2000]}
                            count={voterDataList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>

        </>
    );
}


