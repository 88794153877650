import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
import BoothService from "./Locality/Service/boothService";
import TrackService from "./Locality/Service/trackService";
import VoterService from "./Locality/Service/voterService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Track() {
    const tableHeaders = ['Village', 'Booth', 'Voters Polled','ourVotersPolled','percentage', 'Edit', 'Delete'];
    const classes = useStyles();
    const [districtList, setDistrictList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    const [trackList, setTrackList] = useState([]);
    const [boothList, setBoothList] = useState([]);
    const [voter, setVoter] = useState([]);
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [tabelIdList, setTabelIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [track, setTrack] = useState({
        districtId: '',
        constituencyId: '',
        villageId: '',
        countId: '',
        votersPolled: '',
        ourVotersPolled: '',
        percentage: '',
    });

    const validationSchema = Yup.object().shape({
        districtId: Yup.string().required('district is required'),
        constituencyId: Yup.string().required('constituency is required'),
        villageId: Yup.string().required('village is required'),
        countId: Yup.string().required('count is required'),
        votersPolled: Yup.string().required('voters polled is required'),
        ourVotersPolled: Yup.string().required(' required'),
        votersPolled: Yup.string().required(' required'),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        //getConstituencyList();
        getTrackList();
        return () => {
            setDistrictList([]);
            setConstituencyList([]);
            setVillageList([]);
            setTrackList([]);
        }
    }, []);
    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConstituencyList = (destId) => {
        const disListI = { districtId: destId };
        ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {

            setConstituencyList(res);

        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVillageList = (constituencyId) => {
        const villListId = { constituencyId: constituencyId };
        VillageService.getVillageByConstituencyId(villListId).then((res) => {
            setVillageList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getBoothList = (villageId) => {
        const boothListId = { villageId: villageId };
        BoothService.getBoothByVillageId(boothListId).then((res) => {
            setBoothList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVoter = (countId) => {
        const votesListId = { countId: countId };
        VoterService.getVotersByBoothId(votesListId).then((res) => {
            setVoter(res);
            if (res.length > 0) {
                console.log(res[0].votes)
            }
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getTrackList = () => {
        TrackService.getAllTrack().then((res) => {
            setTrackList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
        const createObjectConstituency = {
            districtId: data.district,
            constituency: data.constituency,
        }
    };


    const editTrack = (track) => {
        const obj = JSON.parse(JSON.stringify(track));
        obj.constituencyId = track.constituencyId ? track.constituencyId._id : '';
        obj.districtId = track.districtId ? track.districtId._id : '';
        obj.villageId = track.villageId ? track.villageId._id : '';
        obj.countId = track.countId ? track.countId._id : '';
        getConstituencyList(obj.districtId);
        getVillageList(obj.constituencyId);
        getBoothList(obj.villageId);
        setTrack(obj);
        handleOpen();
    }
    const deleteTrack = (trackdelete) => {
        if (trackdelete) {
            TrackService.deleteTrack(trackdelete).then((res) => {
                getTrackList();
            }).catch((err) => {
            });
        }
    };

    const formik = useFormik({
        initialValues: track,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (track._id) {
                TrackService.upadeTrack(values).then((res) => {
                    handleClose();
                    getTrackList();
                    resetForm()
                    alert(" Track Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                TrackService.creteTrack(values).then((res) => {
                    getTrackList();
                    resetForm();
                    handleClose();
                    alert(" Track Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
            <PageTitle title="Poll Tracking" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#30875b' }}> Add Poll Tracking
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trackList.slice(pg * rpg, pg * rpg + rpg).map((track) => (
                                    <TableRow key={track._id}>
                                        <TableCell className="pl-3 fw-normal" >{track.villageId?.village}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{track.countId?.count}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{track.votersPolled}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{track.ourVotersPolled}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{track.percentage}</TableCell>
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editTrack(track)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteTrack(track)} />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 100, 500, 1000]}
                            count={trackList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Poll Tracking</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">District</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="districtId"
                                label="District"
                                type="text"
                                variant="standard"
                                value={formik.values.districtId}
                                // getConstituencyList();
                                onChange={e => {
                                    formik.handleChange(e);
                                    getConstituencyList(e.target.value)
                                }}
                                error={formik.touched.districtId && Boolean(formik.errors.districtId)}
                                helperText={formik.touched.districtId && formik.errors.districtId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {districtList.map(({ _id, district }) => (
                                    <MenuItem key={_id} value={_id}>{district}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Constituency </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                margin="dense"
                                name="constituencyId"
                                label="Constituency"
                                type="text"
                                variant="standard"
                                value={formik.values.constituencyId}
                                onChange={e => {
                                    formik.handleChange(e);
                                    getVillageList(e.target.value)
                                }}
                                error={formik.touched.constituencyId && Boolean(formik.errors.constituencyId)}
                                helperText={formik.touched.constituencyId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {constituencyList.map(({ _id, constituency }) => (
                                    <MenuItem key={_id} value={_id}>{constituency}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Village </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}

                                margin="dense"
                                name="villageId"
                                label="Village"
                                type="text"
                                variant="standard"
                                value={formik.values.villageId}
                                onChange={e => {
                                    formik.handleChange(e);
                                    getBoothList(e.target.value)
                                }}
                                error={formik.touched.villageId && Boolean(formik.errors.villageId)}
                                helperText={formik.touched.villageId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {villageList.map(({ _id, village }) => (
                                    <MenuItem key={_id} value={_id}>{village}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Booth </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                margin="dense"
                                name="countId"
                                label="Booth"
                                type="text"
                                variant="standard"
                                value={formik.values.countId}
                                onChange={e => {
                                    formik.handleChange(e);
                                    getVoter(e.target.value)
                                }}
                                error={formik.touched.countId && Boolean(formik.errors.countId)}
                                helperText={formik.touched.countId && formik.errors.countId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {boothList.map(({ _id, count }) => (
                                    <MenuItem key={_id} value={_id}>{count}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="votersPolled"
                            name="votersPolled"
                            label="Number Of Voters Polled"
                            type="text"
                            variant="standard"
                            value={formik.values.votersPolled}
                            onChange={formik.handleChange}
                            error={formik.touched.votersPolled && Boolean(formik.errors.votersPolled)}
                            helperText={formik.touched.votersPolled && formik.errors.votersPolled}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="ourVotersPolled"
                            name="ourVotersPolled"
                            label="Our Voters Polled"
                            type="text"
                            variant="standard"
                            value={formik.values.ourVotersPolled}
                            onChange={formik.handleChange}
                            error={formik.touched.ourVotersPolled && Boolean(formik.errors.ourVotersPolled)}
                            helperText={formik.touched.ourVotersPolled && formik.errors.ourVotersPolled}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="percentage"
                            name="percentage"
                            label="Percentage"
                            type="text"
                            variant="standard"
                            value={formik.values.percentage}
                            onChange={formik.handleChange}
                            error={formik.touched.percentage && Boolean(formik.errors.percentage)}
                            helperText={formik.touched.percentage && formik.errors.percentage}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


