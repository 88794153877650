import requests from './httpService';
const VoterDataService = {
  
  getAllVoterData() {
    return requests.get(`/voterdata/list`);
  },
  
  voterDataReport(body) {
    return requests.post(`/voterdata/report`,body);
  },
  getConstituencyByDistrictId(body){
    return requests.post('/voterdata/getConstituencyByDistrictId',body); 
  },
  getVillageByConstituencyId(body){
    return requests.post('/voterdata/getVillageByConstituencyId',body); 
  },
  getBoothByVillageId(body){
    return requests.post('/voterdata/getBoothByVillageId',body); 
  },
  getCountByVillageId(body){
    return requests.post('/voterdata/getCountByVillageId',body); 
  },
  creteVoterData(body){
    return requests.post('/voterdata/add',body); 
  },
  deleteVoterData(body){
    return requests.delete(`/voterdata/${body._id}`); 
  },
  findVoterDataList(body){
    return requests.post(`/voterdata/find`,body); 
  },
  upadeVoterData(body) {
    return requests.put(`/voterdata/${body._id}`,body); 
  },
  getVotersByBoothId(body){
    return requests.post('/voterdata/getVotersByBoothId',body); 
  },
};
export default VoterDataService;
