import requests from './httpService';
const VillageService = {
  getAllVillage() {
    return requests.get(`/village/list`);
  },
  getConstituencyByDistrictId(body){
    return requests.post('/village/getConstituencyByDistrictId',body); 
  },
  getVillageByConstituencyId(body){
    return requests.post('/village/getVillageByConstituencyId',body); 
  },
  getCountByVillageId(body){
    return requests.post('/village/getCountByVillageId',body); 
  },
  creteVillage(body){
    return requests.post('/village/add',body); 
  },
  deleteVillage(body){
    return requests.delete(`/village/${body._id}`); 
    
  },
  findVillageList(body){
    return requests.post(`/village/find`,body); 
  },
 
  upadeVillaget(body) {
    return requests.put(`/village/${body._id}`,body); 
  },
  uploadImage(body){
    return requests.post('/village/data/upload',body); 
  },
};

export default VillageService;
