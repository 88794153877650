import requests from './httpService';
const BoothService = {
  getAllBooth() {
    return requests.get(`/booth/list`);
  },
  getBoothById(id) {
    return requests.get(`/booth/${id}`);
  },
  getConstituencyByDistrictId(body){
    return requests.post('/booth/getConstituencyByDistrictId',body); 
  },
  getVillageByConstituencyId(body){
    return requests.post('/booth/getVillageByConstituencyId',body); 
  },
  getBoothByVillageId(body){
    return requests.post('/booth/getBoothByVillageId',body); 
  },
  getCountByVillageId(body){
    return requests.post('/booth/getCountByVillageId',body); 
  },
  creteBooth(body){
    return requests.post('/booth/add',body); 
  },
  deleteBooth(body){
    return requests.delete(`/booth/${body._id}`); 
  },
  findBoothList(body){
    return requests.post(`/booth/find`,body); 
  },
  upadeBooth(body) {
    return requests.put(`/booth/${body._id}`,body); 
  },
  

};
export default BoothService;
