import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Village() {
    const tableHeaders = ['District', 'Constituency', 'Village','Edit', 'Delete'];
    const classes = useStyles();
    const [districtList, setDistrictList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    const [formData, setFormData] = useState("");
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    var [uploadError, setUploadError] = useState({isShowError:false,successCount:0,error:[],message:""});
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [village, setVillage] = useState({
        districtId: '',
        constituencyId: '',
        village:'',
    });
    const validationSchema = Yup.object().shape({
        districtId: Yup.string().required('district is required'),
        constituencyId: Yup.string().required('constituency is required'),
        village: Yup.string().required('village is required'),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        //getConstituencyList();
        getVillageList();
        return () => {
            setDistrictList([]);
            setConstituencyList([]);
            setVillageList([]);
        }
    }, []);
    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    // const getSubActivityList = (event, obj) => {
    //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    //     const getsubActList = { schooleId: userDetails.schoolId, activityId: event.target.value };
    //     SubActivityService.getAllSubActivityByActivityId(getsubActList).then((res) => {
    //         setSubActivityList(res);
    //         if (obj) {
    //             setActivity(obj);
    //         }
    //     }).catch((err) => {
    //         // setError(err.message);
    //     });
    // }
    const getConstituencyList = (destId) => {
      const disListI = { districtId: destId };
        ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {
            setConstituencyList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVillageList = () => {
        VillageService.getAllVillage().then((res) => {
            setVillageList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpen1 = () => {
        setOpen1(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };
    const uploadFile = () => {
       // console.log(JSON.stringify(data, null, 2));
        VillageService.uploadImage(formData).then((res) => {
        
        alert(res.message);
        res.isShowError = true;
            setUploadError(res)
            getVillageList();
            })
    };


    const handleChangeImage = (evt) => {
        console.log("Uploading");
        var file = evt.target.files[0];
        let data = new FormData();
        data.append('image', file);
        setFormData(data)
        // reader.onload = function (upload) {
        //   console.log(upload.target.result)
        //   setImage(upload.target.result)
        // };
        // reader.readAsDataURL(file);
    
        console.log("Uploaded");
    
      }
  
    const editVillage = (village) => {
        const obj = JSON.parse(JSON.stringify(village));
        obj.constituencyId = village.constituencyId ? village.constituencyId._id : '';
        obj.districtId = village.districtId ? village.districtId._id : '';
        getConstituencyList(obj.districtId);
        setVillage(obj);
        handleOpen();
    }
    const deleteVillage = (villagedelete) => {
        if (villagedelete) {
            VillageService.deleteVillage(villagedelete).then((res) => {
                getVillageList();
            }).catch((err) => {
            });
        }
    };

 
    const formik = useFormik({
        initialValues: village,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (village._id) {
                VillageService.upadeVillaget(values).then((res) => {
                    handleClose();
                    getVillageList();
                    resetForm()
                    alert(" Village Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                VillageService.creteVillage(values).then((res) => {
                    getVillageList();
                    resetForm();
                    handleClose();
                    alert(" Village Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
         <Grid container spacing={2}>
                <Grid item xs={10}>
            <PageTitle title="Village"   button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#30875b' }}> Add Village
            </Button> }
             />
             
             </Grid>
             <Grid item xs={2}>
             <PageTitle   button={<Button
                variant="contained" onClick={handleOpen1}
                size="medium"
                color="secondary" style={{ backgroundColor: '#30875b', padding:'10px' }}> Data Upload
            </Button> }
             />
              <Dialog open={open1} onClose={handleClose1}>
              <DialogTitle>Data Upload </DialogTitle>
              <form onSubmit={formik.handleSubmit} >
                  <DialogContent style={{ width: 308 }}>
                  <div style={{ marginTop: 16 }} >
                   {uploadError.isShowError ? <div>
                        <div><span style={{color:"green"}}>Total Uploaded: </span> {uploadError?.successCount}</div>
                        <div> <span style={{color:"red"}}>Failed: </span> {uploadError?.error.map((res)=><span>{res.villages}</span>)}</div>
                    </div> :""}

              <lable>File Upload</lable>
              <div>
                <input style={{ marginTop: 7 }}
                  accept="xlsx/*"
                  className={classes.input}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={handleChangeImage}
                />
              </div>
            </div>    
                  </DialogContent>
                  </form>
                  <DialogActions>
                      <Button onClick={handleClose1}>Cancel</Button>
                      <Button onClick={uploadFile} type="submit">Upload</Button>
                  </DialogActions>
             
          </Dialog>
            
            </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {villageList.slice(pg * rpg, pg * rpg + rpg).map((village) => (
                                    <TableRow key={village._id}>
                                        <TableCell className="pl-3 fw-normal" >{village.districtId.district}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{village.constituencyId?.constituency}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{village.village}</TableCell>
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editVillage(village)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteVillage(village)} />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[ 10,  50, 100, 200, 1000]}
                            count={villageList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Village</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">District</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="districtId"
                                label="District"
                                type="text"
                                variant="standard"
                                value={formik.values.districtId}
                                // getConstituencyList();
                                onChange={e => {
                                    formik.handleChange(e);
                                    getConstituencyList(e.target.value)
                                }}
                                error={formik.touched.districtId && Boolean(formik.errors.districtId)}
                                helperText={formik.touched.districtId && formik.errors.districtId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {districtList.map(({ _id, district }) => (
                                    <MenuItem key={_id} value={_id}>{district}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Constituency </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="constituencyId"
                                label="Constituency"
                                type="text"
                                variant="standard"
                                value={formik.values.constituencyId}
                                onChange={formik.handleChange}
                                error={formik.touched.constituencyId && Boolean(formik.errors.constituencyId)}
                                helperText={formik.touched.constituencyId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {constituencyList.map(({ _id, constituency }) => (
                                    <MenuItem key={_id} value={_id}>{constituency}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="village"
                            name="village"
                            label="Village"
                            type="text"
                            variant="standard"
                            value={formik.values.village}
                            onChange={formik.handleChange}
                            error={formik.touched.village && Boolean(formik.errors.village)}
                            helperText={formik.touched.village && formik.errors.village}
                        /> 
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


