import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
import BoothService from "./Locality/Service/boothService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Booth() {
    const tableHeaders = ['District', 'Constituency', 'Village', 'Booth','Status', 'Edit', 'Delete'];
    const classes = useStyles();
    const [districtList, setDistrictList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    const [boothList, setBoothList] = useState([]);
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [tabelIdList, setTabelIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [booth, setBooth] = useState({
        districtId: '',
        constituencyId: '',
        villageId: '',
        count: '',
        status: true,
    });
    const validationSchema = Yup.object().shape({
        districtId: Yup.string().required('district is required'),
        constituencyId: Yup.string().required('constituency is required'),
        villageId: Yup.string().required('village is required'),
        count: Yup.string().required('count is required'),
        count: Yup.string(),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        //getConstituencyList();
        getBoothList();
        return () => {
            setDistrictList([]);
            setConstituencyList([]);
            setVillageList([]);
        }
    }, []);
    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConstituencyList = (destId) => {
        const disListI = { districtId: destId };
        ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {

            setConstituencyList(res);

        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVillageList = (constituencyId) => {
        const villListId = { constituencyId: constituencyId };
        VillageService.getVillageByConstituencyId(villListId).then((res) => {
            setVillageList(res);

        }).catch((err) => {
            setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
        const createObjectConstituency = {
            districtId: data.district,
            constituency: data.constituency,
        }
    };
    const getBoothList = () => {
        BoothService.getAllBooth().then((res) => {
            setBoothList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }



    const editBooth = (booth) => {
        const obj = JSON.parse(JSON.stringify(booth));
        obj.constituencyId = booth.constituencyId ? booth.constituencyId._id : '';
        obj.districtId = booth.districtId ? booth.districtId._id : '';
        obj.villageId = booth.villageId ? booth.villageId._id : '';

        getConstituencyList(obj.districtId);
        getVillageList(obj.constituencyId);

        setBooth(obj);

        handleOpen();
    }
    const deleteBooth = (boothdelete) => {
        if (boothdelete) {
            BoothService.deleteBooth(boothdelete).then((res) => {
                getBoothList();
            }).catch((err) => {
            });
        }
    };

    // const formReset = () => {
    //     const villageSelectData = {
    //         district: '',
    //         constituency: '',
    //         village:'',
    //         id: '',
    //     }
    //     setVillage(villageSelectData)
    // }
    const formik = useFormik({
        initialValues: booth,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.partyId = userDetails.partyId;
            if (booth._id) {
                BoothService.upadeBooth(values).then((res) => {
                    handleClose();
                    getBoothList();
                    resetForm()
                    alert(" Booth Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                BoothService.creteBooth(values).then((res) => {
                    getBoothList();
                    resetForm();
                    handleClose();
                    alert(" Booth Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
            <PageTitle title="Booth" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#30875b' }}> Add Booth
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {boothList.slice(pg * rpg, pg * rpg + rpg).map((booth) => (
                                    <TableRow key={booth._id}>
                                        <TableCell className="pl-3 fw-normal" >{booth.districtId?.district}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{booth.constituencyId?.constituency}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{booth.villageId?.village}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{booth.count}</TableCell>
                                        <TableCell> {booth.status ? 'Active' : 'In Active'} </TableCell>
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editBooth(booth)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteBooth(booth)} />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5,  50, 500, 1000, 5000]}
                            count={boothList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Booth</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">District</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="districtId"
                                label="District"
                                type="text"
                                variant="standard"
                                value={formik.values.districtId}
                                // getConstituencyList();
                                onChange={e => {
                                    formik.handleChange(e);
                                    getConstituencyList(e.target.value)
                                }}
                                error={formik.touched.districtId && Boolean(formik.errors.districtId)}
                                helperText={formik.touched.districtId && formik.errors.districtId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {districtList.map(({ _id, district }) => (
                                    <MenuItem key={_id} value={_id}>{district}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Constituency </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}

                                margin="dense"
                                name="constituencyId"
                                label="Constituency"
                                type="text"
                                variant="standard"
                                value={formik.values.constituencyId}
                                onChange={e => {
                                    formik.handleChange(e);
                                    getVillageList(e.target.value)
                                }}
                                error={formik.touched.constituencyId && Boolean(formik.errors.constituencyId)}
                                helperText={formik.touched.constituencyId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {constituencyList.map(({ _id, constituency }) => (
                                    <MenuItem key={_id} value={_id}>{constituency}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Village </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}

                                margin="dense"
                                name="villageId"
                                label="Village"
                                type="text"
                                variant="standard"
                                value={formik.values.villageId}
                                onChange={formik.handleChange}
                                error={formik.touched.villageId && Boolean(formik.errors.villageId)}
                                helperText={formik.touched.villageId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {villageList.map(({ _id, village }) => (
                                    <MenuItem key={_id} value={_id}>{village}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="count"
                            placeholder="If have more booths Ex:Booth 1-5"
                            name="count"
                            label="Booth (Ex: Booth 1-5)"
                            type="text"
                            variant="standard"
                            value={formik.values.count}
                            onChange={formik.handleChange}
                            error={formik.touched.count && Boolean(formik.errors.count)}
                            helperText={formik.touched.count && formik.errors.count}
                        />
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="status"
                                
                                name="status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                helperText={formik.touched.status && formik.errors.status}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>In Active</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


