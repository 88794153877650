import requests from './httpService';
const DistrictService = {


  getAllDistrict() {
    return requests.get(`/district/list`);
  },

  creteDistrict(body){
    return requests.post('/district/add',body); 
  },

  deleteDistrict(body){
    return requests.delete(`/district/${body._id}`); 
    
  },
  findDistrictList(body){
    return requests.post(`/district/find`,body); 
  },

  upadeDistrict(body) {
    return requests.put(`/district/${body._id}`,body); 
  },

userPermitions(){
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
switch(userDetails.role){
case 'BOOTH':
return {studentDetailsEdit:true, studentDetailsDelete:true,
  //  studentActivityEdit:true, studentActivityDelete:true
  }
case 'TEACHER':
return {studentDetailsEdit:false, studentDetailsDelete:false,
  studentActivityEdit:false, studentActivityDelete:false}
case 'BOOth':
return {ageEdit:false}
}
}
};




export default DistrictService;
