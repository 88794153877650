import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
import BoothService from "./Locality/Service/boothService";
import VoterService from "./Locality/Service/voterService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Voter() {
    const tableHeaders = ['District', 'Constituency', 'Village','Booth','Voters','Edit', 'Delete'];
    const classes = useStyles();
    const [districtList, setDistrictList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    const [voterList, setVoterList] = useState([]);
    const [boothList, setBoothList] = useState([]);
    var [error, setError] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [voter, setVoter] = useState({
        districtId: '',
        constituencyId: '',
        villageId:'',
        countId:'',
        voter:'',
    });
    const validationSchema = Yup.object().shape({
        districtId: Yup.string().required('district is required'),
        constituencyId: Yup.string().required('constituency is required'),
        villageId: Yup.string().required('village is required'),
        countId:Yup.string().required('count is required'),
        voter:Yup.string().required('count is required'),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        //getConstituencyList();
        getVoterList();
        return () => {
            setDistrictList([]);
            setConstituencyList([]);
            setVillageList([]);
            setVoterList([]);
        }
    }, []);
    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConstituencyList = (destId) => {
      const disListI = { districtId: destId };
        ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {
            setConstituencyList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVillageList = (constituencyId) => {
        const villListId = { constituencyId: constituencyId };
        VillageService.getVillageByConstituencyId(villListId).then((res) => {
            setVillageList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getBoothList = (villageId) => {
        const boothListId = { villageId: villageId };
        BoothService.getBoothByVillageId(boothListId).then((res) => {
            debugger
            setBoothList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
   
    const getVoterList = () => {
        let postData = {}; 
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        if (userDetails && userDetails.role === 'BOOTH') {
           // getBoothById();
           postData.countId=userDetails._id;
        }
        VoterService.findVoterList(postData).then((res) => {
            setVoterList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    const editVoter = (voter) => {
        const obj = JSON.parse(JSON.stringify(voter));
        obj.constituencyId = voter.constituencyId ? voter.constituencyId._id : '';
        obj.districtId = voter.districtId ? voter.districtId._id : '';
        obj.villageId = voter.villageId ? voter.villageId._id : '';
        obj.countId= voter.countId ? voter.countId._id : '';
        getConstituencyList(obj.districtId);
        getVillageList(obj.constituencyId);
        getBoothList(obj.villageId);
        setVoter(obj);
        handleOpen();
    }
    const deleteVoter = (voterdelete) => {
        if (voterdelete) {
            VoterService.deleteVoter(voterdelete).then((res) => {
                getVoterList();
            }).catch((err) => {
            });
        }
    };
 
    const formik = useFormik({
        initialValues: voter,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (voter._id) {
               VoterService.upadeVoter(values).then((res) => {
                    handleClose();
                    getVoterList();
                    resetForm()
                    alert(" Voter Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                VoterService.creteVoter(values).then((res) => {
                    getVoterList();
                    resetForm();
                    handleClose();
                    alert(" Voter Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
            <PageTitle title="Voter" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#30875b' }}> Add Voter
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {voterList.slice(pg * rpg, pg * rpg + rpg).map((voter) => (
                                    <TableRow key={voter._id}>
                                        <TableCell className="pl-3 fw-normal" >{voter.districtId?.district}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voter.constituencyId?.constituency}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voter.villageId?.village}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voter.countId?.count}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voter.voter}</TableCell>
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editVoter(voter)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteVoter(voter)} />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 10, 25, 50, 75, 1000]}
                            count={voterList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Voter</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">District</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="districtId"
                                label="District"
                                type="text"
                                variant="standard"
                                value={formik.values.districtId}
                                // getConstituencyList();
                                onChange={e => {
                                    formik.handleChange(e);
                                    getConstituencyList(e.target.value)
                                }}
                                error={formik.touched.districtId && Boolean(formik.errors.districtId)}
                                helperText={formik.touched.districtId && formik.errors.districtId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {districtList.map(({ _id, district }) => (
                                    <MenuItem key={_id} value={_id}>{district}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Constituency </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                margin="dense"
                                name="constituencyId"
                                label="Constituency"
                                type="text"
                                variant="standard"
                                value={formik.values.constituencyId}
                                onChange={e => {
                                    formik.handleChange(e);
                                    getVillageList(e.target.value)
                                }}
                                error={formik.touched.constituencyId && Boolean(formik.errors.constituencyId)}
                                helperText={formik.touched.constituencyId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {constituencyList.map(({ _id, constituency }) => (
                                    <MenuItem key={_id} value={_id}>{constituency}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Village </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                
                                margin="dense"
                                name="villageId"
                                label="Village"
                                type="text"
                                variant="standard"
                                value={formik.values.villageId}
                                onChange={e => {
                                    formik.handleChange(e);
                                    getBoothList(e.target.value)
                                }}
                                error={formik.touched.villageId && Boolean(formik.errors.villageId)}
                                helperText={formik.touched.villageId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {villageList.map(({ _id, village }) => (
                                    <MenuItem key={_id} value={_id}>{village}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Booth </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                
                                margin="dense"
                                name="countId"
                                label="Booth"
                                type="text"
                                variant="standard"
                                value={formik.values.countId}
                                onChange={formik.handleChange}
                                error={formik.touched.countId && Boolean(formik.errors.countId)}
                                helperText={formik.touched.countId && formik.errors.countId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {boothList.map(({ _id, count }) => (
                                    <MenuItem key={_id} value={_id}>{count}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="voter"
                            name="voter"
                            label="Enter Number Of Voters"
                            type="text"
                            variant="standard"
                            value={formik.values.voter}
                            onChange={formik.handleChange}
                            error={formik.touched.voter && Boolean(formik.errors.voter)}
                            helperText={formik.touched.voter && formik.errors.voter}
                        /> 
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


