import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import DistrictService from "./Locality/Service/districtService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import {  useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function District() {
    const tableHeaders = [ 'District','Edit', 'Delete'];
    const classes = useStyles();
    const [districtList, setDistrictList] = useState([]);
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [tabelIdList, setTabelIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [district, setDistrict] = useState({
        district:'',
     
    });
    const validationSchema = Yup.object().shape({
        district:Yup.string().required('district is required'),
  
    });
    const handleChangePage=(event, newpage) =>{
        setpg(newpage);
    }
    const handleChangeRowsPerPage=(event)=> {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        return () => {
            setDistrictList([]);
        }
    }, []);
    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
    };
    const editDistrict = (district) => {
        setDistrict(district);
        handleOpen();
    }
 
    const deleteDistrict = (districtdelete) => {
        if (districtdelete) {
            DistrictService.deleteDistrict(districtdelete).then((res) => {
                getDistrictList();
            }).catch((err) => {
            });
        }
    };
 
    const formik = useFormik({
        initialValues: district,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (district._id) {
                DistrictService.upadeDistrict(values).then((res) => {
                    
                    handleClose();
                    getDistrictList();
                    resetForm()
                    alert("District Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                DistrictService.creteDistrict(values).then((res) => {
                    
                    getDistrictList();
                    resetForm();
                    handleClose();
                    alert(" District Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        
                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
            <PageTitle title="District" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#30875b' }}> Add District
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {districtList.slice(pg * rpg, pg * rpg + rpg).map((district) => (
                                    <TableRow key={district._id}>
                                         <TableCell className="pl-3 fw-normal" >{district.district }</TableCell>

                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editDistrict(district)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteDistrict(district)} />
                                        </TableCell>
        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5,  100,1000]}
                            count={districtList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add District</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style= {{ width: 308 }}>
                    
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="district"
                            name="district"
                            label="District"
                            type="text"
                            variant="standard"
                            value={formik.values.district}
                            onChange={formik.handleChange}
                            error={formik.touched.district && Boolean(formik.errors.district)}
                            helperText={formik.touched.district && formik.errors.district}
                        />  
                       
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


