import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import VoterDataService from "./Locality/Service/voterDataService";
import TrackVotersService from "./Locality/Service/trackVotersService";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
 import BoothService from "./Locality/Service/boothService";
import TrackService from "./Locality/Service/trackService";
import VoterService from "./Locality/Service/voterService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function TrackVoters() {
    const tableHeaders = ['Booth','Voters','Votes Polled','Our Voters Polled', 'Percentage'];
    const classes = useStyles();
    const [districtList, setDistrictList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    const [voterList, setVoterList] = useState([]);
    const [trackVotersList, setTrackVotersList] = useState([]);
    const [boothList, setBoothList] = useState([]);
    const [voterDataList, setVoterDataList] = useState([]);
    const [voters, setvoters] = useState(0);
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [tabelIdList, setTabelIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [trackVoters, setTrackVoters] = useState({  
        votesPolled:'',
    });
    
    
    const validationSchema = Yup.object().shape({
        votesPolled:Yup.string().required('votes polled is required'),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        getVoters();
        //getConstituencyList();
        getTrackVotersList();
        getVoterList();
        getVoterDataList();
        return () => {
            setDistrictList([]);
            setConstituencyList([]);
            setVillageList([]);
            setTrackVotersList([]);
            setVoterList([]);
            setVoterDataList([]);
        }
    }, []);
    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConstituencyList = (destId) => {
      const disListI = { districtId: destId };
        ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {
           
            setConstituencyList(res);
           
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVillageList = (constituencyId) => {
        const villListId = { constituencyId: constituencyId };
        VillageService.getVillageByConstituencyId(villListId).then((res) => {
            setVillageList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getBoothList = (villageId) => {
        const boothListId = { villageId: villageId };
        BoothService.getBoothByVillageId(boothListId).then((res) => {
            setBoothList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVoters = () => {
       // const userDetails = JSON.parse(sessionStorage.getItem("userDetail"));
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));

        const voterListId = { countId: userDetails._id };
       
        VoterService.getVotersByBoothId( voterListId).then((res) => {
            
            if(res.length>0){
                
setvoters(Number(res[0].voter));


            }
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getTrackVotersList = () => {
       // const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        let postData = 'ADMIN'; 
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        if (userDetails && userDetails.role === 'BOOTH') {
           // getBoothById();
           postData=userDetails._id;
        }else{
            postData='ADMIN';
        }

        TrackVotersService.getAllTrackVoters(postData).then((res) => {
            setTrackVotersList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
        const createObjectConstituency = {
            districtId: data.district,
            constituency: data.constituency,
        }
    };
    const getVoterList = () => {
        VoterService.getAllVoter().then((res) => {
            setVoterList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVoterDataList = () => {
        VoterDataService.getAllVoterData().then((res) => {
            setVoterDataList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const editVoter = (voter) => {
        const obj = JSON.parse(JSON.stringify(voter));
        obj.constituencyId = voter.constituencyId ? voter.constituencyId._id : '';
        obj.districtId = voter.districtId ? voter.districtId._id : '';
        obj.villageId = voter.villageId ? voter.villageId._id : '';
        obj.countId= voter.countId ? voter.countId._id : '';
        getConstituencyList(obj.districtId);
        getVillageList(obj.constituencyId);
        getBoothList(obj.villageId);
        setTrackVoters(obj);
        handleOpen();
    }
    // const deleteVoter = (voterdelete) => {
    //     if (voterdelete) {
    //         VoterService.deleteVoter(voterdelete).then((res) => {
    //             getVoterList();
    //         }).catch((err) => {
    //         });
    //     }
    // };
 
    const formik = useFormik({
        initialValues: trackVoters,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(sessionStorage.getItem("userDetail"));
            if (trackVoters._id) {
               VoterService.upadeVoter(values).then((res) => {
                    handleClose();
                    getTrackVotersList();
                    resetForm()
                    alert(" Voter Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
               
                console.log(JSON.parse(localStorage.getItem("userDetail")))
                const userDetails = JSON.parse(localStorage.getItem("userDetail"));
                values.voter = voters;
                values.countId = userDetails._id;
                TrackVotersService.creteTrackVoters( values).then((res) => {
                    getTrackVotersList();
                    resetForm();
                    handleClose();
                    
                    alert(" Voter Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
            <PageTitle title="Track Voters Booth Wise" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"  disabled={trackVotersList.length > 0 ? true : false}
                color="secondary" style={{ backgroundColor: '#30875b' }}> Add Votes Polled
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trackVotersList.slice(pg * rpg, pg * rpg + rpg).map((trackVoters) => (
                                    <TableRow key={trackVoters._id}>
                                        <TableCell className="pl-3 fw-normal" >{trackVoters.countId?.count}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{trackVoters.voter}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{trackVoters.votesPolled}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{trackVoters.ourVotesPolled}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{trackVoters.polledPercentage}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5,  100, 1000, 3000]}
                            count={trackVotersList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Votes Polled</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="votesPolled"
                            name="votesPolled"
                            label="Number Of Voters Polled"
                            type="text"
                            variant="standard"
                            value={formik.values.votesPolled}
                            onChange={formik.handleChange}
                            error={formik.touched.votesPolled && Boolean(formik.errors.votesPolled)}
                            helperText={formik.touched.votesPolled && formik.errors.votesPolled}
                        /> 
                         
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


