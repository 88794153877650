import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
import BoothService from "./Locality/Service/boothService";
import ContactService from "./Locality/Service/contactService";
import CasteService from "./Locality/Service/casteService";
import PartyService from "./Locality/Service/partyService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Contact() {
    const tableHeaders = ['District', 'Constituency', 'Village','name','Mobile','Caste','Party','Significance','Edit', 'Delete'];
    const classes = useStyles();
    const [districtList, setDistrictList] = useState([]);
    const [casteList, setCasteList] = useState([]);
    const [partyList, setPartyList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [addContactByConstituenceyIdList, setAddContactByConstituenceyIdList] = useState([]);
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [tabelIdList, setTabelIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [contact, setContact] = useState({
        districtId: '',
        constituencyId: '',
        villageId:'',
        name :'',
        gender:'',
        age :'',
        mobile:'',
        casteId:'',
        partyId:'',
        significance:'',
    });
    const validationSchema = Yup.object().shape({
        districtId: Yup.string().required('district is required'),
        constituencyId: Yup.string().required('constituency is required'),
        villageId: Yup.string().required('village is required'),
        name:Yup.string().required('name is required'),
        gender: Yup.string().required('gender is required'),
        age: Yup.string().required().matches(/^[0-9]+$/, "Must be only digits"),
        mobile: Yup.string().required()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits'),
        casteId:Yup.string().required('caste is required'),
        partyId:Yup.string().required('party is required'),
        significance:Yup.string().required('significance is required'),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        addContactByConstituenceyId();
        //getConstituencyList();
        //  getContactList();
         getCasteList();
         getPartyList();
        return () => {
            setDistrictList([]);
            setConstituencyList([]);
            setVillageList([]);
        }
    }, []);
    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConstituencyList = (destId) => {
      const disListI = { districtId: destId };
        ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {
           
            setConstituencyList(res);
           
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getVillageList = (constituencyId) => {
        const villListId = { constituencyId: constituencyId };
        VillageService.getVillageByConstituencyId(villListId).then((res) => {
            setVillageList(res);
           
        }).catch((err) => {
            setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
    };
    // const getContactList = () => {
    //     ContactService.getAllContact().then((res) => {
    //         setContactList(res);
    //     }).catch((err) => {
    //         setError(err.message);
    //     });
    // }
    // const getSubActivityList = (event, obj) => {
    //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    //     const getsubActList = {schooleId:userDetails.schoolId, activityId:event.target.value};
    //     SubActivityService.getAllSubActivityByActivityId(getsubActList).then((res) => {
    //         setSubActivityList(res);
    //         if(obj){
    //             setActivity(obj);
    //         }
    //     }).catch((err) => {
    //     });
    // }
    const addContactByConstituenceyId = () => {
        let postData = {}; 
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        if (userDetails && userDetails.role === 'BOOTH') {
           // getBoothById();
           postData.constituencyId=userDetails.constituencyId;
        }
        ContactService.addContactByConstituenceyId(postData).then((res) => {
           
            setContactList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getCasteList = () => {
        CasteService.getAllCaste().then((res) => {
            setCasteList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getPartyList = () => {
        PartyService.getAllParty().then((res) => {
            setPartyList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const editContact = (contact) => {
        const obj = JSON.parse(JSON.stringify(contact));
        obj.constituencyId = contact.constituencyId ? contact.constituencyId._id : '';
        obj.districtId = contact.districtId ? contact.districtId._id : '';
        obj.villageId = contact.villageId ? contact.villageId._id : '';
        obj.casteId = contact.casteId ? contact.casteId._id : '';
        obj.partyId = contact.partyId ? contact.partyId._id : '';
        getConstituencyList(obj.districtId);
        getVillageList(obj.constituencyId);
        
        setContact(obj);
        
        handleOpen();
    }
    const deleteContact = (contactdelete) => {
        if (contactdelete) {
            ContactService.deleteContact(contactdelete).then((res) => {
                addContactByConstituenceyId();
            }).catch((err) => {
            });
        }
    };
    const formik = useFormik({
        initialValues: contact,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (contact._id) {
                ContactService.upadecontact(values).then((res) => {
                    handleClose();
                    addContactByConstituenceyId();
                    
                    resetForm()
                    alert(" Contact Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                ContactService.creteContact(values).then((res) => {
                    addContactByConstituenceyId();
                   
                    resetForm();
                    handleClose();
                    alert(" Contact Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
            <PageTitle title="Contact" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#30875b' }}> Add Contact
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contactList.slice(pg * rpg, pg * rpg + rpg).map((contact) => (
                                    <TableRow key={contact._id}>
                                        <TableCell className="pl-3 fw-normal" >{contact.districtId?.district}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{contact.constituencyId?.constituency}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{contact.villageId?.village}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{contact.name}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{contact.mobile}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{contact.casteId?.caste}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{contact.partyId?.party}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{contact.significance}</TableCell>
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editContact(contact)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteContact(contact)} />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5,  100,1000 ]}
                            count={contactList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Contact</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">District</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="districtId"
                                label="District"
                                type="text"
                                variant="standard"
                                value={formik.values.districtId}
                                // getConstituencyList();
                                onChange={e => {
                                    formik.handleChange(e);
                                    getConstituencyList(e.target.value)
                                }}
                                error={formik.touched.districtId && Boolean(formik.errors.districtId)}
                                helperText={formik.touched.districtId && formik.errors.districtId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {districtList.map(({ _id, district }) => (
                                    <MenuItem key={_id} value={_id}>{district}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Constituency </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                
                                margin="dense"
                                name="constituencyId"
                                label="Constituency"
                                type="text"
                                variant="standard"
                                value={formik.values.constituencyId}
                                onChange={e => {
                                    formik.handleChange(e);
                                    getVillageList(e.target.value)
                                }}
                                error={formik.touched.constituencyId && Boolean(formik.errors.constituencyId)}
                                helperText={formik.touched.constituencyId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {constituencyList.map(({ _id, constituency }) => (
                                    <MenuItem key={_id} value={_id}>{constituency}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Village </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                
                                margin="dense"
                                name="villageId"
                                label="Village"
                                type="text"
                                variant="standard"
                                value={formik.values.villageId}
                                onChange={formik.handleChange}
                                error={formik.touched.villageId && Boolean(formik.errors.villageId)}
                                helperText={formik.touched.villageId && formik.errors.constituencyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {villageList.map(({ _id, village }) => (
                                    <MenuItem key={_id} value={_id}>{village}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                              <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Name"
                            type="text"
                            variant="standard"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        /> 
                         <FormControl component="fieldset">
                    <FormLabel component="legend" >Gender</FormLabel>
                    <RadioGroup aria-label="gender" name="gender"  margin="dense"
                    id="gender  "  type="radio "
                    variant="standard"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                    helperText={formik.touched.gender && formik.errors.gender}
                    >
                      <FormControlLabel value="Male" control={<Radio />} label="Male" />
                      <FormControlLabel value="Female" control={<Radio />} label="Female" />

                    </RadioGroup>
                  </FormControl>
                  <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="age"
                            name="age"
                            label="Age"
                            type="text"
                            variant="standard"
                            value={formik.values.age}
                            onChange={formik.handleChange}
                            error={formik.touched.age && Boolean(formik.errors.age)}
                            helperText={formik.touched.age && formik.errors.age}
                        /> 
                         <TextField
                    InputProps={{ style: { width: 258 } }}
                    
                    margin="dense"
                    id="mobile"
                    name="mobile"
                    label="Mobile Number"
                    type="text"
                    variant="standard"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                    helperText={formik.touched.mobile && formik.errors.mobile}
                  />
                  <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Caste</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="casteId"
                                label="Caste"
                                type="text"
                                variant="standard"
                                value={formik.values.casteId}
                                // getConstituencyList();
                                onChange={formik.handleChange}
                                error={formik.touched.casteId && Boolean(formik.errors.casteId)}
                                helperText={formik.touched.casteId && formik.errors.casteId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {casteList.map(({ _id, caste }) => (
                                    <MenuItem key={_id} value={_id}>{caste}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Party</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                autoFocus
                                margin="dense"
                                name="partyId"
                                label="Party"
                                type="text"
                                variant="standard"
                                value={formik.values.partyId}
                                // getConstituencyList();
                                onChange={formik.handleChange}
                                error={formik.touched.partyId && Boolean(formik.errors.partyId)}
                                helperText={formik.touched.partyId && formik.errors.partyId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {partyList.map(({ _id, party }) => (
                                    <MenuItem key={_id} value={_id}>{party}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                    InputProps={{ style: { width: 258 } }}
                    margin="dense"
                    id="significance"
                    name="significance"
                    label="Significance"
                    type="text"
                    variant="standard"
                    value={formik.values.significance}
                    onChange={formik.handleChange}
                    error={formik.touched.significance && Boolean(formik.errors.significance)}
                    helperText={formik.touched.significance && formik.errors.significance}
                  />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


