import React, { useState } from "react";
import {
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell,
    Card
} from "@material-ui/core";
import ExportExcel from "../../Excelexport";
import { Box } from "@material-ui/core";
import BoothService from "./Locality/Service/boothService";
import DistrictService from "./Locality/Service/districtService";
import ConstituencyService from "./Locality/Service/constituencyService";
import VillageService from "./Locality/Service/villageService";
import VoterDataService from "./Locality/Service/voterDataService";
import CasteService from "./Locality/Service/casteService";
import PartyService from "./Locality/Service/partyService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function VoterDataReports() {
    const tableHeaders = ['Booth Name',
        'Totel Votes', 'Our Voters', 'Percentage', 'Date',];
    const classes = useStyles();
    const [count, setCount] = useState([]);
    const [family, setFamily] = useState({});
    const [districtList, setDistrictList] = useState([]);
    const [casteList, setCasteList] = useState([]);
    const [partyList, setPartyList] = useState([]);
    const [constituencyList, setConstituencyList] = useState([]);
    const [voterDataReportList, setvoterDataReportList] = useState([]);
    const [villageList, setVillageList] = useState([]);
    const [voterDataList, setVoterDataList] = useState([]);
    const [boothList, setBoothList] = useState([]);
    var [error, setError] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [familyOpen, setFamilyOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    var [partyId, setPartyId] = useState("");
    var [casteId, setCasteId] = useState("");
    const [result, setResult] = useState([]);
    const [partyIdList, setPartyIdList] = useState("");
    const [casteIdList, setCasteIdList] = useState("");

    const [voterData, setVoterData] = useState({
        districtId: '',
        constituencyId: '',
        villageId: '',
        countId: '',
        name: '',
        livies: '',
        family: '',
        mobile: '',
        casteId: '',
        partyId: '',
        status: '',
    });
    const validationSchema = Yup.object().shape({
        districtId: Yup.string().required('district is required'),
        constituencyId: Yup.string().required('constituency is required'),
        villageId: Yup.string().required('village is required'),
        countId: Yup.string().required('count is required'),
        name: Yup.string().required('name is required'),
        livies: Yup.string().required('livies in villege is required'),
        family: Yup.string().required('family no is required'),
        mobile: Yup.string().required()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits'),
        casteId: Yup.string().required('caste is required'),
        partyId: Yup.string().required('party is required'),
        status: Yup.string().required('status is required'),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        getDistrictList();
        getVoterDataList();
        getCasteList();
        getPartyList();
        // onSubmit();
        return () => {
            setDistrictList([]);
            setConstituencyList([]);
            setVillageList([]);
            setVoterDataList([]);
            setPartyList([]);
            setPartyIdList([]);
            setCasteIdList([]);
        }
    }, []);
    const excelExport = (res) => {

        const result = res.map((response) => {

            return {

                "District": response.districtId ? response.districtId.district : '',
                "Constituency": response.constituencyId ? response.constituencyId.constituency : '',
                "Village": response.villageId ? response.villageId.village : '',
                "Booth": response.countId ? response.countId.count : '',
                "Voter Count": response.voterId ? response.voterId.voter : '',
                "Sum Family ": response.sumFamily,
                "Percentage ": response.percentage,

            }
        })
        //  setResult(result);
    };


    const getDistrictList = () => {
        DistrictService.getAllDistrict().then((res) => {
            setDistrictList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConstituencyList = (destId) => {
        const disListI = { districtId: destId };
        ConstituencyService.getConstituencyByDistrictId(disListI).then((res) => {
            setConstituencyList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }

    const getVillageList = (constituencyId) => {
        const villListId = { constituencyId: constituencyId };
        VillageService.getVillageByConstituencyId(villListId).then((res) => {
            setVillageList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const familyHandleOpen = () => {
        setFamilyOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const familyHandleClose = () => {
        setFamilyOpen(false);
    };

    const getVoterDataList = () => {
       let filterData= {};
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        
        if(userDetails && userDetails.role === 'ADMIN'){
            filterData = {countId:''};
        }else{
            filterData = {countId:userDetails._id};
        }
        VoterDataService.voterDataReport(filterData).then((res) => {


            const extractedData = res.map(item => ({

                family: item.family ? item.family : "",
                boothId: item.countId ? item.countId._id : "",
                boothName: item.countId ? item.countId.count : "",
                voterCount: item.voterId ? item.voterId.voter : "",
                createdAt: dateAndTime(item.createdAt)
            }));
            console.log(JSON.stringify(extractedData));
            // Use reduce to group objects by boothId
            const groupedData = extractedData.reduce((groups, item) => {
                const { boothId, ...rest } = item; // Extract boothId and the rest of the object
                if (!groups[boothId]) {
                    groups[boothId] = [];
                }
                groups[boothId].push(rest); // Push the rest of the object to the corresponding group
                return groups;
            }, {});
            console.log(JSON.stringify(groupedData));
            const result = Object.entries(groupedData).map(([key, group]) => {
                const sum = group.reduce((acc, item) => acc + parseInt(item.family), 0);
                return group.map((item) => ({
                    ...item,
                    bootId: key,
                    sumFamily: sum,
                    percentage: (sum / parseInt(item.voterCount) * 100).toFixed(2)

                }));
            });
            const flatArray = [].concat(...result);
            console.log(JSON.stringify(flatArray));
            const uniqueData = [];
            const uniqueBootIds = new Set();

            flatArray.forEach(item => {
                if (!uniqueBootIds.has(item.bootId)) {
                    uniqueData.push(item);
                    uniqueBootIds.add(item.bootId);
                }
            });

            const booth = uniqueData.map(items => {
                
                return {
                    "Booth Name": items.boothName,
                     "Voter Count": items.voterCount,
                    "Sum Family": items.sumFamily,
                     "Percentage": items.percentage,
                      "Date And Time": items.createdAt
                };
            })

            setResult(booth);
            setVoterDataList(uniqueData);


        })

    }
    const dateAndTime = (date) => {

        const systemDate = new Date(date);
        const formattedDateTime = systemDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            // hour: 'numeric',
            // minute: 'numeric',
            // second: 'numeric',
            hour12: true,

        });
        return formattedDateTime;
    }


    const getCasteList = () => {
        CasteService.getAllCaste().then((res) => {
            setCasteList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const getPartyList = () => {
        PartyService.getAllParty().then((res) => {
            setPartyList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }

    return (
        <>
            <PageTitle title="Voters Reports" />
            <Card sx={{ maxWidth: 345 }}>
                <Box   >
                    <div >
                        <form >
                            <Grid container spacing={3} columns={12} style={{ margin: 10 }}  >

                                <Grid item xs={12} sm={3} md={3} >
                                    <ExportExcel excelData={result} fileName={'Voter Data'} />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Box>
            </Card>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {voterDataList.slice(pg * rpg, pg * rpg + rpg).map((voterData) => (
                                    <TableRow key={voterData._id}>
                                        <TableCell className="pl-3 fw-normal" >{voterData.boothName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voterData.voterCount}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voterData.sumFamily}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voterData.percentage}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{voterData.createdAt}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 50, 1000, 2000]}
                            count={voterDataList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>


        </>
    );
}


