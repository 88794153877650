import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import CategoryServices from "../../services/CategoryServices";

import {
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  OpacityRounded,
  HotTub as HotTub,
  ArtTrack as ArtTrack,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  Brightness5Rounded,
  QuestionAnswer as SupportIcon,
  AccessibilityOutlined,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  SvgIconComponent as SvgIconComponent,
  CalendarToday as CalendarToday,
  AccessAlarmSharp as AccessAlarmSharp,
  Create as Create,
  SupervisedUserCircle,
  HowToVoteSharp ,
  HistorySharp,
  OpenInNew,
  EvStation
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// styles
import useStyles from "./styles";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

let structure = [
  {
    id: 1,
    label: " Masters",
    icon: <UIElementsIcon />,
    children: [
       { label: "District", link: "/app/district" },
       { label: "Constituency", link: "/app/constituency" },
      { label: "Village", link: "/app/village" },
      { label: "Booth", link: "/app/booth" },
      {
        label: "Caste",
        link: "/app/caste"
      },
      {
        id: 5,
        label: "Party",
        link: "/app/party"
      },
     
    ],
  },
  {
    id: 2,
    label: "Booth Wise Voters",
    link: "/app/voter",
    icon: <HotTub />,
  },
  
  {
    id: 3,
    label: "I-Contacts",
    link: "/app/contact",
    icon: <AccessAlarmSharp />,
  },
  {
    id: 4,
    label: "Representive Contact ",
    link: "/app/representivecontact",
    icon: <LibraryIcon />,
  },
  {
    id: 5,
    label: "Family Wise Voters Data",
    link: "/app/VoterData",
    icon: <ArrowBackIcon />,
  },
  {
    id: 8,
    label: "Party Wise Voters Data",
    link: "/app/partywisevoterdata",
    icon: <Brightness5Rounded />,
  },
  {
    id: 6, 
    label: "Profile Registration",
    link: "/app/profileregistration",
    icon: <HistorySharp />,
  }, 
  {
    id: 7,
    label: "Track voters Booth wise",
    link: "/app/boothtrack",
    icon: <AccessibilityOutlined />,
  },
  {
    id: 7,
    label: "Our Voters List",
    link: "/app/OurVoter",
    icon: <HowToVoteSharp />,
  },
  //  {
  //   id: 8, 
  //   label: "Poll Tracking",
  //   link: "/app/track",
  //   icon: <ArtTrack />,
  // },
  {
    id: 9, 
    label: "Voters Statistics",
    link: "/app/reportofvoter",
    icon: <  EvStation
    />,
  },
  {
    id: 10,
    label: " Reports",
    icon:  <FAQIcon />,
    children: [
       { label: "Representive Reports", link: "/app/representivereports" },
       { label: "Contact Reports", link: "/app/reportsofcontact" },
      { label: "Voters Reports", link: "/app/votorsreports" },
  
    ],
  },
  // {
  //   id: 9,
  //   label: "Create Events",
  //   link: "/app/createevents",
  //   icon: <Create />,
  // },
  // { label: "Special Observation", link: "/app/specialobservation",
  // icon:<OpenInNew />},
  //   {
  //   id: 9,
  //   label: "Reports",
  //   link: "/app/reports",
  //   icon: <TableIcon />,
  // },

  // { id: 5, type: "divider" },
  // { id: 6, type: "title", label: "HELP" },
  // { id: 7, label: "Library", link: "https://flatlogic.com/templates", icon: <LibraryIcon /> },
  // { id: 8, label: "Support", link: "https://flatlogic.com/forum", icon: <SupportIcon /> },
  // { id: 9, label: "FAQ", link: "https://flatlogic.com/forum", icon: <FAQIcon /> },
  // { id: 10, type: "divider" },
  // { id: 11, type: "title", label: "PROJECTS" },
  // {
  //   id: 12,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="small" color="warning" />,
  // },
  // {
  //   id: 13,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="small" color="primary" />,
  // },
  // {
  //   id: 14,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="small" color="secondary" />,
  // },
];
const booth=[
  {
    id: 2,
    label: "Booth Wise Voters",
    link: "/app/voter",
    icon: <HotTub />,
  },
  // {
  //   id: 7, 
  //   label: "Poll Tracking",
  //   link: "/app/track",
  //   icon: <ArtTrack />,
  // },

  {
    id: 3,
    label: "I-Contacts",
    link: "/app/contact",
    icon: <AccessAlarmSharp />,
  },
  {
    id: 4,
    label: "Representive Contact ",
    link: "/app/representivecontact",
    icon: <LibraryIcon />,
  },
  
  {
    id: 5,
    label: "Family Wise Voters Data",
    link: "/app/VoterData",
    icon: <ArrowBackIcon />,
  },
  {
    id: 8,
    label: "Party Wise Voters Data",
    link: "/app/partywisevoterdata",
    icon: <LibraryIcon />,
  },
  {
    id: 7,
    label: "Our Voters List",
    link: "/app/OurVoter",
    icon: <HowToVoteSharp />,
  },
  {
    id: 8,
    label: "Track voters Booth wise",
    link: "/app/boothtrack",
    icon: <AccessibilityOutlined />,
  },
  {id: 9,
     label: "Voters Reports",
   link: "/app/votorsreports",
   icon: <OpacityRounded /> },
]; 
const student=[
    
  // { id: 1, label: "Add Class", link: "/app/addclass", icon: <TableIcon /> },
  // {
  //   id: 3,
  //   label: "Teacher",
  //   link: "/app/teacher",
  //   icon: <UIElementsIcon />,
  // },
];


const userDetails = JSON.parse(localStorage.getItem("userDetail"));
if(userDetails && userDetails.role === 'ADMIN'){
  structure = structure;
}else if(userDetails && userDetails.role === 'BOOTH'){
  structure = booth;
}
function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );
  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;
    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
