import requests from './httpService';
const PartyService = {
  getAllParty() {
    return requests.get(`/party/list`);
  },

  creteParty(body){
    return requests.post('/party/add',body); 
  },
  getAllPartyById(id,body){
    return requests.post(`/party/list/${id}`,body); 
  },
  deleteParty(body){
    return requests.delete(`/party/${body._id}`); 
    
  },
  findPartyList(body){
    return requests.post(`/party/find`,body); 
  },
  upadeParty(body) {
    return requests.put(`/party/${body._id}`,body); 
  },

};

export default PartyService;
