import requests from './httpService';
const TrackService = {
  getAllTrack() {
    return requests.get(`/track/list`);
  },
  getConstituencyByDistrictId(body){
    return requests.post('/track/getConstituencyByDistrictId',body); 
  },
  getVillageByConstituencyId(body){
    return requests.post('/track/getVillageByConstituencyId',body); 
  },
  
  getCountByVillageId(body){
    return requests.post('/track/getCountByVillageId',body); 
  },
  creteTrack(body){
    return requests.post('/track/add',body); 
  },
  deleteTrack(body){
    return requests.delete(`/track/${body._id}`); 
  },
  findTrackList(body){
    return requests.post(`/track/find`,body); 
  },
  upadeTrack(body) {
    return requests.put(`/track/${body._id}`,body); 
  },
  

};
export default TrackService;
