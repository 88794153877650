import requests from './httpService';
const ConstituencyService = {
  getAllConstituency() {
    return requests.get(`/constituency/list`);
  },
  getConstituencyByDistrictId(body){
    
    return requests.post('/constituency/getConstituencyByDistrictId',body); 
  },
  creteConstituency(body){
    return requests.post('/constituency/add',body); 
  },
  deleteConstituency(body){
    return requests.delete(`/constituency/${body._id}`); 
    
  },
  findConstituencyList(body){
    return requests.post(`/constituency/find`,body); 
  },
 
  upadeConstituencyt(body) {
    return requests.put(`/constituency/${body._id}`,body); 
  },

};

export default ConstituencyService;
